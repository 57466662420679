export enum SupportBackendRoutes {
  auth = '/api/v2/user/auth',
  findUserByIds = '/api/v2/user/find-by-id',
  findUserByKey = '/api/v3/user/find-by-key-total',
  getUserToken = '/api/v1/user/get-user-token',
  getUserLogs = '/api/v3/user/get-user-logs-list',
  getUserLogsItem = '/api/v1/user/:userId/logs/:logId',
  getOrganizations = '/api/v1/user/get-user-orgs',
  getSmsCode = '/api/v1/user/send-user-sms-code',
  getEmailCode = '/api/v1/user/send-user-email-code',
  getUserCode = '/api/v1/user/user-codes',
  getUserCodeInfo = '/api/v1/user/user-codes-info',
}
