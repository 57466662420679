import { FC } from 'react';
import InputMask from 'react-input-mask';
import cn from 'classnames';
import { IInputProps, InputStates } from './input.models';
import './input.styles.scss';

export const Input: FC<IInputProps> = ({
  className,
  hintText,
  icon,
  iconButtonProps,
  inputWrapperProps,
  inputWrapperClassName,
  state = InputStates.Default,
  ...props
}) => {
  const inputClasses = {
    base: 'input',
    state: {
      default: 'input_default',
      warning: 'input_warning',
      success: 'input_success',
      error: 'input_error',
    },
  };
  const inputCN = cn(
    inputClasses.base,
    className,
    {
      [inputClasses.state[state]]: !!state,
    },
  );
  const inputHintTextClasses = {
    base: 'input__hint-text',
    state: {
      default: 'input__hint-text_default',
      warning: 'input__hint-text_warning',
      success: 'input__hint-text_success',
      error: 'input__hint-text_error',
    },
  };
  const inputHintTextCN = cn(
    inputHintTextClasses.base,
    className,
    {
      [inputHintTextClasses.state[state]]: !!state,
    },
  );
  const inputWrapperClasses = {
    base: 'input__wrapper',
  };
  const inputWrapperCN = cn(
    inputWrapperClasses.base,
    inputWrapperClassName,
  );

  return (
    <>
      <div
        className={inputWrapperCN}
        {...inputWrapperProps}
      >
        {
          props.mask ? (
            <InputMask className={inputCN} onChange={props.onChange} value={props.value} mask={props.mask} {...props} />
          ) : (
            <input
              className={inputCN}
              {...props}
            />
          )
        }
        {icon && (
          <button
            className="input__icon-button"
            {...iconButtonProps}
          >
            {icon}
          </button>
        )}
      </div>
      {!!hintText && (
        <p className={inputHintTextCN}>
          {hintText}
        </p>
      )}
    </>
  );
};
