import { FC, Fragment, useMemo } from 'react';
import { observer } from 'mobx-react';
import { Pagination } from '../../components/pagination';
import {
  ITableColumn,
  Table,
} from '../../components/table';
import { LogsListColumns } from './logs-list.columns';
import { LogsEmpty } from './logs-list.empty';
import { ITableLogsData, ITableLogsProps } from './logs-list.models';

export const LogsTable: FC<ITableLogsProps> = observer((props) => {
  const columns = useMemo<ITableColumn<ITableLogsData>[]>(
    () => LogsListColumns,
    [],
  );

  const TypedTable = Table<ITableLogsData>;
  const tableLogsData: ITableLogsData[] | undefined = props.logs?.map(
    (log): ITableLogsData => ({
      ...log,
      _id: log.log_id,
    }),
  );

  return (
    <Fragment>
      <div className="logs-table__wrapper">
        <TypedTable
          columns={columns}
          data={tableLogsData}
          rowRender={props.rowRender}
          className="logs-table"
        />
      </div>
      <LogsEmpty
        logs={props.logs}
        loading={props.loading}
      >
        <Pagination
          total={props.total}
          page={props.page}
          minPage={props.minPage}
          maxPage={props.maxPage}
          limit={props.limit}
          limitsOptions={props.limitsOptions}
          onPrevClick={props.onPrevClick}
          onNextClick={props.onNextClick}
          onLimitChange={props.onLimitChange}
        />
      </LogsEmpty>
    </Fragment>
  );
});
