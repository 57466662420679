import { FC, SVGProps } from 'react';

export const UserAvatarIcon: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 33"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect
      y="0.5"
      width="32"
      height="32"
      rx="16"
      fill="#0071CE"
      fillOpacity="0.1"
    />
    <path
      d="M18.6668 13.1667C18.6668 13.8739 18.3859 14.5522 17.8858 15.0523C17.3857 15.5524 16.7074 15.8333 16.0002 15.8333C15.2929 15.8333 14.6146 15.5524 14.1145 15.0523C13.6144 14.5522 13.3335 13.8739 13.3335 13.1667C13.3335 12.4594 13.6144 11.7811 14.1145 11.281C14.6146 10.781 15.2929 10.5 16.0002 10.5C16.7074 10.5 17.3857 10.781 17.8858 11.281C18.3859 11.7811 18.6668 12.4594 18.6668 13.1667V13.1667ZM16.0002 17.8333C14.7625 17.8333 13.5755 18.325 12.7003 19.2002C11.8252 20.0753 11.3335 21.2623 11.3335 22.5H20.6668C20.6668 21.2623 20.1752 20.0753 19.3 19.2002C18.4248 18.325 17.2378 17.8333 16.0002 17.8333V17.8333Z"
      stroke="#0071CE"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
