import { useEffect, useState } from 'react';
import useDebounceFn from 'ahooks/es/useDebounceFn';

export interface IWindowSize {
  width: number;
  height: number;
}

export const useWindowSize = (): IWindowSize => {
  const [windowSize, setWindowSize] = useState<IWindowSize>({
    width: 0,
    height: 0,
  });

  const { run: handleResize } = useDebounceFn((): void => {
    setWindowSize({
      width: window.innerWidth,
      height: window.innerHeight,
    });
  }, {
    wait: 100,
  });

  useEffect(() => {
    window.addEventListener('resize', handleResize);

    handleResize();

    return (): void => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return windowSize;
};
