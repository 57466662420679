import { IErrorsStore } from '../models/stores/errors.store';
import { IRootStore } from '../models/stores/root.models';
import { IUserCodeStore } from '../models/stores/user-codes.models';
import { AccessStore } from './access.store';
import { ConfigStore } from './config.store';
import { ErrorsStore } from './errors.store';
import { OrganizationStore } from './organizations.store';
import { PretenderStore } from './pretender.store';
import { RequestsStore } from './requests.store';
import { UserDataStore } from './search-users-by-ids.store';
import { SearchUsersByKeyStore } from './search-users-by-key.store';
import { UserEmailCode } from './send-email-code.store';
import { UserSmsCode } from './send-sms-code.store';
import { UserCodes } from './user-codes.store';
import { UserLogsFiltersStore } from './user-logs-filters.store';
import { UserLogsItemStore } from './user-logs-item.store';
import { UserLogsStore } from './user-logs.store';
import { UserStore } from './user.store';

export class RootStore implements IRootStore {

  public readonly config: ConfigStore;
  public readonly requests: RequestsStore;
  public readonly errors: IErrorsStore;
  public readonly pretender: PretenderStore;
  public readonly user: UserStore;
  public readonly userLogs: UserLogsStore;
  public readonly userLogsItem: UserLogsItemStore;
  public readonly userLogsFilter: UserLogsFiltersStore;
  public readonly searchUserByKey: SearchUsersByKeyStore;
  public readonly userDataStore: UserDataStore;
  public readonly access: AccessStore;
  public readonly organizations: OrganizationStore;
  public readonly smsCode: UserSmsCode;
  public readonly emailCode: UserEmailCode;
  public readonly userCodes: IUserCodeStore;

  public get today(): Date {
    return new Date();
  }

  constructor() {
    this.config = new ConfigStore();
    this.requests = new RequestsStore(this);
    this.errors = new ErrorsStore();
    this.pretender = new PretenderStore(this);
    this.user = new UserStore(this);
    this.userLogs = new UserLogsStore(this);
    this.userLogsItem = new UserLogsItemStore(this);
    this.searchUserByKey = new SearchUsersByKeyStore(this);
    this.userDataStore = new UserDataStore(this);
    this.access = new AccessStore(this);
    this.userLogsFilter = new UserLogsFiltersStore(this);
    this.organizations = new OrganizationStore(this);
    this.smsCode = new UserSmsCode(this);
    this.emailCode = new UserEmailCode(this);
    this.userCodes = new UserCodes(this);
  }

}
