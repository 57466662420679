import { FC, SVGProps } from 'react';

export const ProfileIcon: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="16"
    height="12"
    viewBox="0 0 16 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M14.6667 0H1.33333C0.606667 0.0266667 0.0266667 0.606667 0 1.33333V10.6667C0.0266667 11.3933 0.606667 11.9733 1.33333 12H14.6667C15.3933 11.9733 15.9733 11.3933 16 10.6667V1.33333C15.9733 0.606667 15.3933 0.0266667 14.6667 0ZM14.6667 10.6667H1.33333V1.33333H14.6667V10.6667ZM9.33333 9.33333V8.5C9.33333 7.39333 7.10667 6.83333 6 6.83333C4.89333 6.83333 2.66667 7.39333 2.66667 8.5V9.33333H9.33333ZM6 2.66667C5.55797 2.66667 5.13405 2.84226 4.82149 3.15482C4.50893 3.46738 4.33333 3.89131 4.33333 4.33333C4.33333 4.5522 4.37644 4.76893 4.4602 4.97114C4.54396 5.17335 4.66672 5.35708 4.82149 5.51184C5.13405 5.82441 5.55797 6 6 6C6.21887 6 6.4356 5.95689 6.63781 5.87313C6.84002 5.78937 7.02375 5.66661 7.17851 5.51184C7.33328 5.35708 7.45604 5.17335 7.5398 4.97114C7.62356 4.76893 7.66667 4.5522 7.66667 4.33333C7.66667 4.11446 7.62356 3.89774 7.5398 3.69553C7.45604 3.49332 7.33328 3.30959 7.17851 3.15482C7.02375 3.00006 6.84002 2.87729 6.63781 2.79353C6.4356 2.70978 6.21887 2.66667 6 2.66667ZM9.33333 2.66667V3.33333H13.3333V2.66667H9.33333ZM9.33333 4V4.66667H13.3333V4H9.33333ZM9.33333 5.33333V6H12V5.33333H9.33333Z"
      fill="white"
    />
  </svg>
);
