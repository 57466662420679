import { Navigate, Outlet, RouteObject } from 'react-router-dom';
import { BrowserRoute } from '../../routes/browser.routes';
import { PageLayout } from '../components/layout';
import { Login } from '../pages/login';
import { NotFound } from '../pages/not-found';
import { User } from '../pages/user';
import { Users } from '../pages/users';

export const appRoutesDefinition: RouteObject[] = [{
  path: BrowserRoute.index,
  element: <Outlet />,
  children: [
    {
      path: BrowserRoute.index,
      element: <Navigate to={BrowserRoute.users} />,
    },
    {
      path: BrowserRoute.login,
      element: <Login />,
    },
    {
      path: BrowserRoute.user,
      element: (
        <PageLayout>
          <User />
        </PageLayout>
      ),
    },
    {
      path: BrowserRoute.userLogs,
      element: (
        <PageLayout>
          <User />
        </PageLayout>
      ),
    },
    {
      path: BrowserRoute.codes,
      element: (
        <PageLayout>
          <User />
        </PageLayout>
      ),
    },
    {
      path: BrowserRoute.profile,
      element: (
        <PageLayout>
          <User />
        </PageLayout>
      ),
    },
    {
      path: BrowserRoute.organizations,
      element: (
        <PageLayout>
          <User />
        </PageLayout>
      ),
    },
    {
      path: BrowserRoute.workspace,
      element: (
        <PageLayout>
          <User />
        </PageLayout>
      ),
    },
    {
      path: BrowserRoute.users,
      element: (
        <PageLayout>
          <Users />
        </PageLayout>
      ),
    },
    {
      path: '*',
      element: (
        <PageLayout>
          <NotFound />
        </PageLayout>
      ),
    },
  ],
}];
