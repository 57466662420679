import { FC } from 'react';
import { ChevronsIcon } from '../icons/chevrons';
import { ICheckboxProps } from './checkbox.models';
import './checkbox.styles.scss';

export const Checkbox: FC<ICheckboxProps> = ({
  label,
  checked = false,
  onClick,
  children,
}) => {
  const checkboxClass = `checkbox ${checked ? 'checked' : ''}`;

  return (
    <div className={'checkboxWrapper'} onClick={onClick}>
      <div className={checkboxClass}>
        <ChevronsIcon className={'checkboxIcon'} />
      </div>
      <div className={'checkboxLabel'}>
        {label || children}
      </div>
    </div>
  );
};
