import { FC, HTMLProps, useRef, useState } from 'react';
import cn from 'classnames';
import { useClickOutside } from '../../../hooks/use-click-outside.hook';
import { Checkbox } from '../checkbox';
import { ChevronsIcon } from '../icons/chevrons';
import { SearchIcon } from '../icons/search';
import { Input } from '../input';
import { IMultiselectOption, IMultiselectProps, IOptionProps } from './multiselect.models';
import './multiselect.styles.scss';

export const MultiSelect: FC<IMultiselectProps> = ({
  icon,
  options,
  defaultValue,
  size = 'md',
  label,
  className,
  dropTop = false,
  dropRight = false,
  error,
  disabled,
  withShadow = true,
  withSearch = true,
  onBlur,
  onChange,
  ...restProps
}) => {
  const wrapperRef = useRef<HTMLDivElement>(null);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const [isOpen, setOpen] = useState<boolean>(false);
  const [searchValue, setSearchValue] = useState<string>('');
  const [searched, setSearched] = useState<IMultiselectOption[]>([]);

  const wrapperProps: HTMLProps<HTMLDivElement> = {
    ...restProps,
    ref: wrapperRef,
    tabIndex: 1,
    onBlur: (event) => {
      if (typeof onBlur === 'function') {
        onBlur(event);
      }
    },
  };

  const toggleDropdown = (): void => {
    setOpen((prev) => !prev);
  };

  useClickOutside(wrapperRef, () => {
    setOpen(false);
    setSearchValue('');
    setSearched([]);
  });

  const onOptionChange = (option: IMultiselectOption): void => {
    if (!option.value) {
      setOpen(false);
      if (typeof onChange === 'function') {
        onChange([]);
      }
      return;
    }
    const haveValue = defaultValue?.findIndex((el) => el.value === option.value) !== -1;
    const newSelected = haveValue ? defaultValue?.filter((el) => el.label !== option.label) : [...defaultValue, option];
    if (typeof onChange === 'function') {
      onChange(newSelected);
    }
  };

  const selectWrapperClasses = {
    base: 'select__wrapper',
    withShadow: 'select__wrapper_withShadow',
  };
  const selectWrapperCN = cn(
    selectWrapperClasses.base,
    {
      [selectWrapperClasses.withShadow]: withShadow,
    },
    className,
  );

  const selectedLabelClasses = {
    base: 'select__selected-label',
    disabled: 'select__selected-label_disabled',
    error: 'select__selected-label_error',
    size: {
      md: 'select__selected-label_md',
      sm: 'select__selected-label_sm',
    },
  };

  const selectedLabelCN = cn(
    selectedLabelClasses.base,
    {
      [selectedLabelClasses.error]: error,
      [selectedLabelClasses.disabled]: disabled,
      [selectedLabelClasses.size[size]]: !!size,
    },
  );

  const dropDownClasses = {
    base: 'select__dropdown',
    visible: 'select__dropdown_visible',
    invisible: 'select__dropdown_invisible',
    dropPos: {
      top: 'select__dropdown_dropTop',
      left: 'select__dropdown_dropLeft',
      right: 'select__dropdown_dropRight',
      bottom: 'select__dropdown_dropBottom',
    },
  };

  const dropDownCN = cn(
    dropDownClasses.base,
    {
      [dropDownClasses.dropPos.top]: dropTop,
      [dropDownClasses.dropPos.bottom]: !dropTop,
      [dropDownClasses.dropPos.right]: dropRight,
      [dropDownClasses.dropPos.left]: !dropRight,
      [dropDownClasses.visible]: isOpen,
      [dropDownClasses.invisible]: !isOpen,
    },
  );

  const iconClasses = {
    base: 'select__icon',
    size: {
      sm: 'select__icon_sm',
      md: 'select__icon_md',
    },
    rotate: 'select__icon_rotate-180',
  };

  const iconCN = cn(
    iconClasses.base,
    {
      [iconClasses.size[size]]: !!size,
      [iconClasses.rotate]: isOpen,
    },
  );

  const onSearchHandleClick = (e: React.MouseEvent): void => {
    e.stopPropagation();
  };

  const onSearchHandleChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const value = e.target.value;
    const newSearched = options.filter((option) => option.label.toLowerCase().includes(value.toLowerCase()));
    setSearchValue(value);
    setSearched(newSearched);
  };

  const Option: FC<IOptionProps> = ({ option }) => {
    const isCurrent = defaultValue.some((opt) => opt.label === option.label);

    const onOptionClick = (e: React.MouseEvent): void => {
      e.stopPropagation();
      onOptionChange(option);
    };

    return (
      <Checkbox label={option.label} onClick={onOptionClick} checked={isCurrent} />
    );
  };

  return (
    <div {...wrapperProps} className={selectWrapperCN}>
      {label &&
        <label className="select__label">
          {label}
        </label>
      }
      <div
        className="select"
        onClick={toggleDropdown}
      >
        <div className={selectedLabelCN}>
          <span className="select__selected-option-label">Действие</span>
          {icon ?? <ChevronsIcon className={iconCN} />}
        </div>
        <div ref={dropdownRef} className={dropDownCN}>
          <div className="select__dropdown__content">
            {withSearch && (
              <>
                <Input
                  state="default"
                  className="select__dropdown__search"
                  inputWrapperClassName="select__dropdown__search__wrapper"
                  value={searchValue}
                  onClick={onSearchHandleClick}
                  placeholder="Поиск"
                  onChange={onSearchHandleChange}
                  icon={<SearchIcon />}
                />
              </>
            )}
            {!!searched.length
              ? searched.map((option, index) => <Option option={option} key={option.label + index} />)
              : options.map((option, index) => <Option option={option} key={option.label + index} />)
            }
          </div>
        </div>
      </div>
      {error &&
        <div className="select__error-text">
          {error}
        </div>
      }
    </div>
  );
};
