import { EnvKeys, IConfigStore } from '../models/stores/config.models';

export class ConfigStore implements IConfigStore {

  public readonly authHost: string;
  public readonly iframeURI: string;
  public readonly supportAPI: string;
  public readonly headerKey: string;
  public readonly localToken: string | undefined;
  public readonly profileURI: string;

  constructor() {
    this.authHost = this.getRequired(EnvKeys.authHost);
    this.iframeURI = this.getRequired(EnvKeys.iframeURI);
    this.headerKey = this.getRequired(EnvKeys.headerKey);
    this.supportAPI = this.getRequired(EnvKeys.supportAPI);
    this.localToken = this.getValue(EnvKeys.localToken);
    this.profileURI = this.getRequired(EnvKeys.profileURI);
  }

  private getValue(key: EnvKeys): string | undefined {
    const value = process.env[key];
    return value;
  }

  private getRequired(key: EnvKeys): string {
    const value = this.getValue(key);
    if (typeof value !== 'string' || value.length === 0) {
      throw new Error(`Cannot find "${key}" parameter from environment`);
    }
    return value;
  }

}
