import { FC, Fragment, PropsWithChildren, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react';
import { BrowserRoute } from '../../../routes/browser.routes';
import { appStoreContext } from '../../../stores/context.store';
import './auth.styles.scss';

export const Auth: FC<PropsWithChildren> = observer((props) => {
  const AppStore = useContext(appStoreContext);

  const navigate = useNavigate();

  useEffect(() => {
    if (!AppStore.user.token) {
      navigate(BrowserRoute.login);
    }
    else {
      AppStore.user.getUserData();
    }
  }, [AppStore.user.token]);

  return (
    <Fragment>
      {props.children}
    </Fragment>
  );
});
