export const emailValidator = (value: string): string | undefined => {
  if (typeof value !== 'string') {
    const message = 'Необходимо указать почту';
    return message;
  }

  const isEmail = (/.+@.+\..+/i).test(value);
  if (!isEmail) {
    const message = 'Неверный формат почты';
    return message;
  }
  return undefined;
};
