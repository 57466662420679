import { useState } from 'react';

export interface IUsersFiltersProps {
  limitsOptions: number[];
  total: number;
}

export interface IUsersFilters {
  limitsOptions: number[];
  limit: number;

  page: number;
  minPage: number;
  maxPage: number;

  setLimit(limit: number): void;
  setPage(page: number): void;
}

export const useUsersFilters = (props: IUsersFiltersProps): IUsersFilters => {
  const [limit, setLimitState] = useState(props.limitsOptions[0]);
  const [page, setPageState] = useState(1);

  const minPage = 1;
  const maxPage = Math.ceil(props.total / limit);

  const setLimit = (value: number): void => {
    const newLimit = props.limitsOptions.find((lo) => lo === value);
    if (newLimit) {
      setLimitState(newLimit);
    }
  };

  const setPage = (newPage: number): void => {
    if (newPage >= minPage && newPage <= maxPage) {
      setPageState(newPage);
    }
  };

  return {
    limitsOptions: props.limitsOptions,
    page,
    minPage,
    maxPage,
    limit,
    setLimit,
    setPage,
  };
};
