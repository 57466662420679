import { FC, Fragment, PropsWithChildren, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react';
import { BrowserRoute } from '../../../routes/browser.routes';
import { appStoreContext } from '../../../stores/context.store';
import { Button } from '../../components/button';
import { Loader } from '../../components/loader';
import './access.styles.scss';
import { LogoutButton } from '../../components/logout';

export const Access: FC<PropsWithChildren> = observer((props) => {
  const AppStore = useContext(appStoreContext);
  const { allowed, loading } = AppStore.access;

  const navigate = useNavigate();

  useEffect(() => {
    AppStore.access.getAuth();
  }, []);

  const redirectToLogin = (): void => {
    navigate(BrowserRoute.login, {
      replace: true,
    });
  };

  const wrapperCN = 'auth__wrapper';
  const blockCN = 'auth__block';
  const iconSize = 48;

  if (loading) {
    return (
      <div className={wrapperCN}>
        <div className={blockCN}>
          <Loader width={iconSize} height={iconSize} />
          <span>Проверка роли...</span>
        </div>
      </div>
    );
  }

  if (!allowed) {
    return (
      <div className={wrapperCN}>
        <div className={blockCN}>
          <span>У вас недостаточно прав</span>
          <Button onClick={redirectToLogin} size="sm">
            Снова авторизоваться
          </Button>
          <LogoutButton />
        </div>
      </div>
    );
  }

  return (
    <Fragment>
      {props.children}
    </Fragment>
  );
});
