import { FC, SVGProps } from 'react';

export const NotFoundFilesIcon: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="51"
    height="50"
    viewBox="0 0 51 50"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M3.625 45.3123V4.68728C3.625 3.82429 4.32451 3.12478 5.1875 3.12478H28.625V9.37478C28.625 11.1007 30.0241 12.4998 31.75 12.4998H38V17.1873H41.125V10.9373C41.1274 10.5219 40.9644 10.1227 40.6719 9.82791L31.2969 0.452906C31.002 0.160425 30.6028 -0.00256296 30.1875 -0.000219209H5.1875C2.59863 -0.000219209 0.5 2.09851 0.5 4.68728V45.3123C0.5 47.9011 2.59863 49.9998 5.1875 49.9998H20.8125V46.8748H5.1875C4.32461 46.8748 3.625 46.1753 3.625 45.3123Z"
      fill="#0071CE"
    />
    <path
      d="M36.4375 21.875C28.671 21.875 22.375 28.171 22.375 35.9375C22.375 43.704 28.671 50 36.4375 50C44.204 50 50.5 43.704 50.5 35.9375C50.5 28.171 44.204 21.875 36.4375 21.875ZM25.5 35.9375C25.5129 29.8969 30.4202 25.0105 36.4608 25.0233C38.8061 25.0283 41.0876 25.787 42.9688 27.1875L27.6875 42.4688C26.274 40.5841 25.5067 38.2934 25.5 35.9375ZM42.9454 44.7108C41.0643 46.1113 38.7827 46.87 36.4375 46.875C34.0816 46.8683 31.7909 46.1011 29.9062 44.6875L45.1875 29.4062C48.7946 34.2517 47.7908 41.1037 42.9454 44.7108Z"
      fill="#0071CE"
    />
  </svg>
);
