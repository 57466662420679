import { FC, Fragment, HTMLAttributes, ReactNode, useMemo } from 'react';
import cn from 'classnames';
import { IOrganization } from '../../../models/entities/organizations.models';
import { NotFoundFilesIcon } from '../../components/icons/not-found-files';
import { Loader } from '../../components/loader';

export interface IOrganizationEmptyProps extends HTMLAttributes<HTMLDivElement> {
  organizations: IOrganization[] | null;
  loading?: boolean;
  children?: ReactNode | undefined;
}

export const OrganizationEmpty: FC<IOrganizationEmptyProps> = ({
  organizations,
  loading,
  ...props
}) => {
  const isEmpty = useMemo(() => (organizations === null), [organizations]);
  const notFound = useMemo(() => (Array.isArray(organizations) && organizations.length <= 0), [organizations]);

  const rootCN = cn('organizations-list-empty', props.className);

  if (loading) {
    return (
      <div className={rootCN} {...props}>
        <div className="organizations-list-empty__content">
          <Loader />
        </div>
      </div>
    );
  }

  if (!notFound && !isEmpty) {
    return (
      <Fragment>
        {props.children}
      </Fragment>
    );
  }

  if (isEmpty) {
    return (
      <div className={rootCN} {...props}>
        <div className="organizations-list-empty__content">
          <NotFoundFilesIcon className="organizations-list-empty__icon" />
          <h3 className="organizations-list-empty__content__title">
            Таблица пустая
          </h3>
        </div>
      </div>
    );
  }

  return (
    <div className={rootCN} {...props}>
      <div className="organizations-list-empty__content">
        <NotFoundFilesIcon className="organizations-list-empty__icon" />
        <h3 className="organizations-list-empty__content__title">
          Организации не найдены
        </h3>
      </div>
    </div>
  );
};
