import { FC, SVGProps } from 'react';

export const SearchIcon: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_134_8518)">
      <path
        d="M18.0001 16.9396L13.3036 12.2431C14.5235 10.751 15.1234 8.84715 14.979 6.92525C14.8346 5.00335 13.957 3.21048 12.5278 1.91747C11.0985 0.624463 9.22702 -0.0697502 7.30031 -0.0215763C5.3736 0.0265975 3.53911 0.813473 2.17629 2.17629C0.813473 3.53911 0.0265975 5.3736 -0.0215763 7.30031C-0.0697502 9.22702 0.624463 11.0985 1.91747 12.5278C3.21048 13.957 5.00335 14.8346 6.92525 14.979C8.84715 15.1234 10.751 14.5235 12.2431 13.3036L16.9396 18.0001L18.0001 16.9396ZM7.50008 13.5001C6.31339 13.5001 5.15335 13.1482 4.16666 12.4889C3.17996 11.8296 2.41093 10.8925 1.9568 9.79618C1.50268 8.69982 1.38386 7.49342 1.61537 6.32954C1.84688 5.16565 2.41832 4.09655 3.25744 3.25744C4.09655 2.41832 5.16565 1.84688 6.32954 1.61537C7.49342 1.38386 8.69982 1.50268 9.79618 1.9568C10.8925 2.41093 11.8296 3.17996 12.4889 4.16666C13.1482 5.15335 13.5001 6.31339 13.5001 7.50008C13.4983 9.09083 12.8656 10.6159 11.7407 11.7407C10.6159 12.8656 9.09083 13.4983 7.50008 13.5001Z"
        fill="#6F7C98"
      />
    </g>
    <defs>
      <clipPath id="clip0_134_8518">
        <rect width="18" height="18" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
