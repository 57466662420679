import { FC, useContext, useEffect, useMemo, useState } from 'react';
import { Navigate, useParams, useSearchParams } from 'react-router-dom';
import { observer } from 'mobx-react';
import { BrowserRoute } from '../../../routes/browser.routes';
import { appStoreContext } from '../../../stores/context.store';
import { getUserFullName } from '../../../utils';
import { Breadcrumbs, IBreadcrumbsRoute } from '../../components/breadcrumbs';
import { ArrowheadLeftIcon } from '../../components/icons/arrowhead-left';
import { Loader } from '../../components/loader';
import { PageTitle } from '../../components/page-title';
import { UserTabs } from '../../content/user-tabs';
import './user.styles.scss';

export const User: FC = observer(() => {
  const AppStore = useContext(appStoreContext);
  const { data: user, loading } = AppStore.userDataStore;
  const { user_id } = useParams();
  const [search] = useSearchParams();

  const [userChecking, setUserChecking] = useState<boolean>(false);

  useEffect(() => {
    if (typeof user_id === 'string' && user_id.length > 0) {
      AppStore.userDataStore.get(user_id);
    }

    setUserChecking(true);

    return AppStore.userDataStore.reset();
  }, []);

  const fullName = useMemo(() => (user ? getUserFullName(user) : ''), [user]);
  const userBreadcrumbs: IBreadcrumbsRoute[] = [
    {
      to: {
        pathname: BrowserRoute.users,
        search: search.toString(),
      },
      name: (
        <>
          <ArrowheadLeftIcon />
          Назад к выбору пользователя
        </>
      ),
    },
  ];
  const iconSize = 48;

  if (!userChecking && loading) {
    return (
      <div className="user">
        <Breadcrumbs routes={userBreadcrumbs} />
        <div className="user__loader_wrapper">
          <div className="user__loader__content">
            <Loader width={iconSize} height={iconSize} />
            <span>Проверка пользователя...</span>
          </div>
        </div>
      </div>
    );
  }

  if (userChecking && !loading && !user) {
    return <Navigate to={BrowserRoute.users} />;
  }

  return (
    <div className="user">
      <Breadcrumbs routes={userBreadcrumbs} />
      <PageTitle>{ fullName }</PageTitle>
      <UserTabs />
    </div>
  );
});
