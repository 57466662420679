import { makeAutoObservable, runInAction } from 'mobx';
import { IUser } from '../models/entities/user.models';
import { IRootStore } from '../models/stores/root.models';
import { IAccessStore } from '../models/stores/support-auth.models';
import { SupportBackendRoutes } from '../routes/backend.routes';

export class AccessStore implements IAccessStore {

  public readonly rootStore: IRootStore;

  public allowed: boolean = false;
  public loading: boolean = false;
  public user: IUser | null = null;

  constructor(rootStore: IRootStore) {
    this.rootStore = rootStore;

    makeAutoObservable(this, {
      rootStore: false,
    });
  }

  public async getAuth(): Promise<void> {
    try {
      runInAction(() => {
        this.allowed = false;
        this.loading = true;
      });

      const response = await this.rootStore.requests.json<IUser | null>({
        method: 'POST',
        baseURL: this.rootStore.config.supportAPI,
        route: SupportBackendRoutes.auth,
        token: this.rootStore.user.token,
      });

      runInAction(() => {
        this.allowed = true;
        this.loading = false;
        this.user = response;
      });
    }
    catch (error) {
      runInAction(() => {
        this.loading = false;
        this.rootStore.errors.setError(error);
      });
    }
  }

}
