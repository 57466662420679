import { HTMLAttributes, InputHTMLAttributes, ReactNode } from 'react';

export enum InputStates {
  Default = 'default',
  Warning = 'warning',
  Success = 'success',
  Error = 'error',
}

export interface IInputProps extends InputHTMLAttributes<HTMLInputElement> {
  state: 'default' | 'warning' | 'error' | 'success';
  hintText?: string;
  icon?: ReactNode;
  iconButtonProps?: HTMLAttributes<HTMLButtonElement>;
  inputWrapperClassName?: string;
  inputWrapperProps?: HTMLAttributes<HTMLDivElement>;
  mask?: string;
}
