import { makeAutoObservable } from 'mobx';
import { IRootStore } from '../models/stores/root.models';
import { IUserLogsFiltersStore } from '../models/stores/user-logs-filters.models';

export class UserLogsFiltersStore implements IUserLogsFiltersStore {

  public limitsOptions: number[] = [100, 200, 400];
  public limit: number = this.limitsOptions[0];

  public page: number = 1;
  public minPage: number = 1;
  public maxPage: number = 1;

  public action: string | null = null;
  public status: string | null = null;
  public service: string | null = null;
  public isSupport: boolean | null = null;
  public startDate: string | null = null;
  public endDate: string | null = null;


  public readonly rootStore: IRootStore;

  constructor(rootStore: IRootStore) {
    this.rootStore = rootStore;

    makeAutoObservable(this, {
      rootStore: false,
    });
  }

  public setMaxPage = (total: number): void => {
    this.maxPage = Math.ceil(total / this.limit);
  };

  public setLimitOptions = (newLimitOptions: number[]): void => {
    if (newLimitOptions.length > 0) {
      this.limitsOptions = newLimitOptions;
    }
  };

  public setPage = (newPage: number): void => {
    if (newPage >= this.minPage && newPage <= this.maxPage) {
      this.page = newPage;
    }
  };

  public setLimit = (value: number): void => {
    const newLimit = this.limitsOptions.find((limitOption) => limitOption === value);
    if (newLimit) {
      this.page = 1;
      this.limit = newLimit;
    }
  };

  public setService = (value?: string | null): void => {
    if (typeof value === 'string' && value.length > 0) {
      this.service = value;
      this.action = null;
    }
    else {
      this.service = null;
    }
  };

  public setAction = (value?: string | null): void => {
    if (typeof value === 'string' && value.length > 0) {
      this.action = value;
    }
    else {
      this.action = null;
    }
  };

  public setStatus = (value?: string | null): void => {
    if (typeof value === 'string' && value.length > 0) {
      this.status = value;
    }
    else {
      this.status = null;
    }
  };

  public setIsSupport = (value?: boolean | null): void => {
    if (typeof value === 'boolean') {
      this.isSupport = value;
    }
    else {
      this.isSupport = null;
    }
  };

  public setStartDate = (value?: string | null): void => {
    if (typeof value === 'string') {
      this.startDate = value;
    }
    else {
      this.startDate = null;
    }
  };

  public setEndDate = (value?: string | null): void => {
    if (typeof value === 'string') {
      this.endDate = value;
    }
    else {
      this.endDate = null;
    }
  };

  public reset = (): void => {
    this.page = 1;
    this.limit = this.limitsOptions[0];
  };

}
