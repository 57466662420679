import { FC, useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { observer } from 'mobx-react';
import { useWindowSize } from '../../../hooks/use-window.hook';
import { appStoreContext } from '../../../stores/context.store';
import { Button } from '../../components/button';
import './profile.styles.scss';

export const Profile: FC = observer(() => {
  const iframeRef = useRef<HTMLIFrameElement | null>(null);
  const { user_id } = useParams();
  const [iFrameKey, setIFrameKey] = useState(0);

  const AppStore = useContext(appStoreContext);
  const { token, loading } = AppStore.pretender;

  const { height } = useWindowSize();
  const frameHeight = height - 32;

  const sendToken = useCallback((): void => {
    const target = iframeRef.current;
    const key = window.btoa(AppStore.config.headerKey);

    if (target?.contentWindow && token) {
      target.contentWindow.postMessage({
        token,
        key,
      }, '*');
    }
  }, [token]);

  useEffect(() => {
    if (iframeRef.current) {
      iframeRef.current.src += '';
      setTimeout((): void => setIFrameKey((key) => key + 1), 1000);
    }
  }, [token]);

  useEffect(() => {
    AppStore.pretender.pretend(user_id);
    return () => AppStore.pretender.reset();
  }, []);

  useEffect(() => {
    const timer = setInterval(sendToken, 1000);
    return () => clearInterval(timer);
  }, [sendToken]);

  return (
    <div className="profile">
      <div
        style={{ display: 'none' }}
        className="profile__controls"
      >
        <Button
          className="profile__controls__button"
          size="sm"
          variant="white"
          disabled={loading && !!iframeRef.current}
          onClick={(): void => setIFrameKey((key) => key + 1)}
        >
          Выполнить вход
        </Button>
      </div>
      <iframe
        className="profile__iframe"
        title="unione"
        key={iFrameKey}
        src={ AppStore.config.profileURI }
        width="100%"
        height={frameHeight}
        ref={iframeRef}
        onLoad={sendToken}
      />
    </div>
  );
});
