import { AxiosError } from 'axios';
import { makeAutoObservable, runInAction } from 'mobx';
import { IOrganization } from '../models/entities/organizations.models';
import { IOrganizationStore } from '../models/stores/organizations.models';
import { IRootStore } from '../models/stores/root.models';
import { SupportBackendRoutes } from '../routes/backend.routes';

export class OrganizationStore implements IOrganizationStore {

  public readonly rootStore: IRootStore;

  public data: IOrganization[] | null = null;
  public loading: boolean = false;

  constructor(rootStore: IRootStore) {
    this.rootStore = rootStore;

    makeAutoObservable(this, {
      rootStore: false,
    });
  }

  public async get(userId: string): Promise<void> {
    try {
      runInAction(() => {
        this.loading = true;
        this.data = null;
      });

      const response = await this.rootStore.requests.json<IOrganization[] | null>({
        method: 'GET',
        baseURL: this.rootStore.config.supportAPI,
        route: SupportBackendRoutes.getOrganizations,
        token: this.rootStore.user.token,
        params: {
          userId,
        },
      });

      runInAction(() => {
        this.loading = false;
        this.data = response;
      });
    }
    catch (error) {
      if (error instanceof AxiosError) {
        const noOrganizations = (error as AxiosError<string>)
          .response?.data.includes('HttpException: user was not found');

        if (noOrganizations) {
          runInAction(() => {
            this.loading = false;
          });

          return;
        }
      }

      runInAction(() => {
        this.rootStore.errors.setError(error);
        this.loading = false;
      });
    }
  }

}
