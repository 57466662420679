import { FC, SVGProps } from 'react';

export const AvatarIcon: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M25.0326 15.0876C25.0326 17.8974 22.7548 20.1752 19.945 20.1752C17.1352 20.1752 14.8574 17.8974 14.8574 15.0876C14.8574 12.2778 17.1352 10 19.945 10C22.7548 10 25.0326 12.2778 25.0326 15.0876Z"
      fill="#7D6FE1"
      stroke="#7D6FE1"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.875 27.251C11.8737 26.8933 11.9536 26.54 12.1089 26.2178C12.5962 25.2432 13.9705 24.7267 15.1108 24.4927C15.9332 24.3172 16.767 24.2 17.6059 24.1419C19.159 24.0054 20.7212 24.0054 22.2744 24.1419C23.1132 24.2007 23.9469 24.3179 24.7694 24.4927C25.9098 24.7267 27.284 25.1945 27.7713 26.2178C28.0836 26.8746 28.0836 27.6371 27.7713 28.2938C27.284 29.3172 25.9098 29.785 24.7694 30.0092C23.948 30.1919 23.1139 30.3125 22.2744 30.3698C21.0102 30.477 19.7402 30.4965 18.4733 30.4283C18.1809 30.4283 17.8983 30.4283 17.6059 30.3698C16.7694 30.3132 15.9386 30.1926 15.1206 30.0092C13.9705 29.785 12.606 29.3172 12.1089 28.2938C11.9544 27.9679 11.8745 27.6116 11.875 27.251Z"
      fill="#7D6FE1"
      stroke="#7D6FE1"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M36.875 20C36.875 29.3198 29.3198 36.875 20 36.875C10.6802 36.875 3.125 29.3198 3.125 20C3.125 10.6802 10.6802 3.125 20 3.125C29.3198 3.125 36.875 10.6802 36.875 20ZM4.63987 20C4.63987 28.4832 11.5168 35.3601 20 35.3601C28.4832 35.3601 35.3601 28.4832 35.3601 20C35.3601 11.5168 28.4832 4.63987 20 4.63987C11.5168 4.63987 4.63987 11.5168 4.63987 20Z"
      fill="#7B61FF"
    />
  </svg>
);
