import JSONPretty from 'react-json-pretty';
import { Tab } from 'rc-tabs/lib/interface';
import { ILogsItem } from '../../../models/entities/logs.models';
import { statusList } from '../errors/errorsDescription';

export const getLogDialogTabs = ({
  body,
  error,
  params,
  query,
  status,
}: ILogsItem): Tab[] | null => {
  const tabs = { body, error, params, query, status: status.toString() };
  const createdTabs = Object.entries(tabs).map(([key, data]) => {
    if (typeof data !== 'string' || data.length <= 0 || data === '{}') {
      return undefined;
    }
    let label = key.charAt(0).toUpperCase() + key.slice(1);
    const regex = /^\d\d\d$/gm;

    if (regex.test(data)) {
      const statusDesc = statusList.filter((item) => item.code === data).map((item) => `${item.code} ${item.description}`)[0];
      label += ' Description';
      return {
        key,
        label,
        children: statusDesc,
      };
    }

    return {
      key,
      label,
      children: <JSONPretty data={data} />,
    };
  });
  const activeTabs = createdTabs.filter((tab) => tab);

  if (activeTabs.length <= 0) {
    return null;
  }

  return activeTabs as Tab[];
};
