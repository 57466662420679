import { FC, useContext, useEffect } from 'react';
import { Toaster } from 'react-hot-toast';
import { observer } from 'mobx-react';
import { appStoreContext } from '../../../stores/context.store';
import { toast } from '../../components/notification';

export const Errors: FC = observer(() => {
  const appStore = useContext(appStoreContext);

  useEffect(() => {
    if (appStore.errors.message) {
      toast({
        type: 'error',
        text: appStore.errors.message,
      });
    }
  }, [appStore.errors.id]);

  return (
    <Toaster />
  );
});
