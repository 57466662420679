import { makeAutoObservable, runInAction } from 'mobx';
import { IRootStore } from '../models/stores/root.models';
import { IUserSmsCode } from '../models/stores/user-sms-code.models';
import { SupportBackendRoutes } from '../routes/backend.routes';
import { toast } from '../views/components/notification';

export class UserSmsCode implements IUserSmsCode {

  public readonly rootStore: IRootStore;

  public loading: boolean = false;

  constructor(rootStore: IRootStore) {
    this.rootStore = rootStore;

    makeAutoObservable(this, {
      rootStore: false,
    });
  }

  public async getSmsCode(userId: string): Promise<void> {
    try {
      runInAction(() => {
        this.loading = true;
      });

      await this.rootStore.requests.request({
        method: 'GET',
        baseURL: this.rootStore.config.supportAPI,
        route: SupportBackendRoutes.getSmsCode,
        token: this.rootStore.user.token,
        params: {
          userId,
        },
      });
      toast({
        type: 'success',
        text: 'СМС было успешно отправлено.',
      });
      runInAction(() => {
        this.loading = false;
      });
    }
    catch (error) {
      runInAction(() => {
        this.loading = false;
        this.rootStore.errors.setError(error);
      });
    }
  }

}
