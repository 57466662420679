import { FC, ReactNode } from 'react';
import { Field, Form } from 'react-final-form';
import cn from 'classnames';
import { composeValidators } from '../../../shared/validators/compose.validators';
import { minLengthValidator } from '../../../shared/validators/min-length.validators';
import { Button } from '../../components/button';
import { CloseIcon } from '../../components/icons/close';
import { ResetIcon } from '../../components/icons/reset';
import { Input } from '../../components/input';
import { ISearchForm, IUsersSearchProps, TFields } from './users-search.models';
import './users-search.styles.scss';

const fieldKeys: TFields[] = [
  'surName',
  'name',
  'patronymic',
  'email',
  'phone',
  'id',
  'snils',
  'docId',
];

const placeholders = {
  name: 'Имя',
  surName: 'Фамилия',
  patronymic: 'Отчество',
  email: 'E-mail',
  phone: 'Номер телефона',
  id: 'ID',
  snils: 'СНИЛС',
  docId: '№ документа (иностран.)',
};

const snilsMask = '999-999-999 99';

export const UsersSearch: FC<IUsersSearchProps> = (props) => (
  <div className="users-search">
    <Form<ISearchForm>
      onSubmit={props.onSubmitHandler}
      render={({ handleSubmit, form }): ReactNode => (
        <form
          className="users-search__form"
          onSubmit={handleSubmit}
        >
          <div className="users-search__form__inputs">
            {
              fieldKeys.map((fieldKey) => (
                <Field
                  name={fieldKey}
                  key={fieldKey}
                  validate={composeValidators(
                    minLengthValidator(1),
                  )}
                  render={({ input, meta }): ReactNode => {
                    const onClickIconButtonHandler = (): void => {
                      form.change(fieldKey, undefined);
                    };

                    const closeIcon = (
                      <div
                        className="users-search__input-icon__wrapper"
                        onClick={(): void => onClickIconButtonHandler()}
                      >
                        <CloseIcon
                          className="users-search__input-icon"
                        />
                      </div>
                    );

                    const errored = !!(meta.error && meta.touched && meta.dirty);
                    const state = errored ? 'error' : 'default';
                    const hintText = errored ? meta.error : '';
                    const placeholder = placeholders[fieldKey] ?? '';
                    const inputCN = cn('users-search__input', `users-search__input--${fieldKey}`);
                    const icon = input.value ? closeIcon : null;
                    const mask = fieldKey === 'snils' ? snilsMask : undefined;

                    return (
                      <div className="users-search__form__input-wrapper">
                        <Input
                          type="text"
                          state={state}
                          placeholder={placeholder}
                          className={inputCN}
                          hintText={hintText}
                          icon={icon}
                          mask={mask}
                          {...input}
                        />
                      </div>
                    );
                  }}
                />
              ))
            }
          </div>
          <div className="users-search__separator" />
          <div className="users-search__form__controls">
            <Button
              type="submit"
              size="sm"
              variant="primary"
              className="users-search__form__controls__button"
            >
              Поиск
            </Button>
            <Button
              size="sm"
              variant="white"
              className="users-search__form__controls__button users-search__form__reset-button"
              onClick={(): void => form.reset()}
            >
              <ResetIcon />
              Очистить фильтр
            </Button>
          </div>
        </form>
      )}
    />
  </div>
);
