import { FC, SVGProps } from 'react';

export const EyeIcon: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M9.99967 7.5C10.6627 7.5 11.2986 7.76339 11.7674 8.23223C12.2363 8.70107 12.4997 9.33696 12.4997 10C12.4997 10.663 12.2363 11.2989 11.7674 11.7678C11.2986 12.2366 10.6627 12.5 9.99967 12.5C9.33663 12.5 8.70075 12.2366 8.23191 11.7678C7.76307 11.2989 7.49967 10.663 7.49967 10C7.49967 9.33696 7.76307 8.70107 8.23191 8.23223C8.70075 7.76339 9.33663 7.5 9.99967 7.5ZM9.99967 3.75C14.1663 3.75 17.7247 6.34167 19.1663 10C17.7247 13.6583 14.1663 16.25 9.99967 16.25C5.83301 16.25 2.27467 13.6583 0.833008 10C2.27467 6.34167 5.83301 3.75 9.99967 3.75ZM2.64967 10C4.02467 12.8 6.86634 14.5833 9.99967 14.5833C13.133 14.5833 15.9747 12.8 17.3497 10C15.9747 7.2 13.133 5.41667 9.99967 5.41667C6.86634 5.41667 4.02467 7.2 2.64967 10Z"
      fill="white"
    />
  </svg>
);
