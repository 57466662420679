import { FC, PropsWithChildren } from 'react';
import { Access } from '../../content/access';
import { Auth } from '../../content/auth';
import { Main } from '../main';
import { Layout } from './layout.view';

export const PageLayout: FC<PropsWithChildren> = ({ children }) => (
  <Auth>
    <Access>
      <Layout>
        <Main>
          {children}
        </Main>
      </Layout>
    </Access>
  </Auth>
);
