import { ReactElement } from 'react';
import { OrganizationIcon } from '../../components/icons/organization';
import { ITableColumn } from '../../components/table';
import { ITableOrganizationData } from './organizations.models';

export const OrganizationColumns: ITableColumn<ITableOrganizationData>[] = [
  {
    name: 'Название',
    value: (data: ITableOrganizationData): ReactElement => (
      <div className="organizations-table__name-cell">
        <div className="organizations-table__name-cell__icon-wrapper">
          <OrganizationIcon className="organizations-table__name-cell__icon" />
        </div>
        <p className="organizations-table__name-cell__name">
          {data.name}
        </p>
      </div>
    ),
  },
  {
    name: 'Описание',
    value: (data: ITableOrganizationData): ReactElement => (
      <div className="organizations-table__desc-cell">
        {data.description}
      </div>
    ),
  },
  {
    name: 'Адрес',
    value: (data: ITableOrganizationData): ReactElement => (
      <div className="organizations-table__address-cell">
        {data.address}
      </div>
    ),
  },
  {
    name: 'Телефон',
    value: (data: ITableOrganizationData): ReactElement => (
      <div className="organizations-table__phone-cell">
        {data.phone}
      </div>
    ),
  },
  {
    name: 'E-mail',
    value: (data: ITableOrganizationData): ReactElement => (
      <div className="organizations-table__email-cell">
        {data.email}
      </div>
    ),
  },
];
