import { FC, SVGProps } from 'react';

export const LogsIcon: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="12"
    height="14"
    viewBox="0 0 12 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path d="M10.6663 5V10.8667C10.6663 11.6134 10.6663 11.9868 10.521 12.272C10.3932 12.5229 10.1892 12.7268 9.93833 12.8547C9.65311 13 9.27974 13 8.53301 13H3.46634C2.7196 13 2.34624 13 2.06102 12.8547C1.81014 12.7268 1.60616 12.5229 1.47833 12.272C1.33301 11.9868 1.33301 11.6134 1.33301 10.8667V3.13333C1.33301 2.3866 1.33301 2.01323 1.47833 1.72801C1.60616 1.47713 1.81014 1.27316 2.06102 1.14532C2.34624 1 2.7196 1 3.46634 1H6.66634M10.6663 5L6.66634 1M10.6663 5H8.79968C8.05294 5 7.67957 5 7.39435 4.85468C7.14347 4.72684 6.9395 4.52287 6.81167 4.27199C6.66634 3.98677 6.66634 3.6134 6.66634 2.86667V1M3.99967 10.3333H7.99967M3.99967 7.66667H7.99967M3.99967 5H4.66634"
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round" />
  </svg>

);
