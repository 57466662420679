import { makeAutoObservable, runInAction } from 'mobx';
import { ILogsList } from '../models/entities/logs.models';
import { IGetUserLogResponse } from '../models/routers/logs.models';
import { IRootStore } from '../models/stores/root.models';
import { IGetUserLogParams, ILogsListsStore } from '../models/stores/user-logs.models';
import { SupportBackendRoutes } from '../routes/backend.routes';

export class UserLogsStore implements ILogsListsStore {

  public readonly rootStore: IRootStore;

  public logs: ILogsList[] | null = null;
  public total: number = 0;
  public loading: boolean = false;

  constructor(rootStore: IRootStore) {
    this.rootStore = rootStore;

    makeAutoObservable(this, {
      rootStore: false,
    });
  }

  public reset(): void {
    this.logs = null;
    this.total = 0;
  }

  public async getUserLogs({
    dateEnd,
    dateStart,
    ...request
  }: IGetUserLogParams): Promise<void> {
    try {
      runInAction(() => {
        this.reset();
        this.loading = true;
      });

      const response = await this.rootStore.requests.json<IGetUserLogResponse>({
        method: 'GET',
        baseURL: this.rootStore.config.supportAPI,
        route: SupportBackendRoutes.getUserLogs,
        token: this.rootStore.user.token,
        params: {
          dateEnd: dateEnd ? new Date(dateEnd).toISOString() : dateEnd,
          dateStart: dateStart ? new Date(dateStart).toISOString() : dateStart,
          ...request,
        },
      });

      runInAction(() => {
        this.loading = false;
        this.total = response.total;
        this.logs = response.logs;
        this.rootStore.userLogsFilter.setMaxPage(response.total);
      });
    }
    catch (error) {
      runInAction(() => {
        this.reset();
        this.rootStore.errors.setError(error);
      });
    }
  }

}
