import { FC, useContext } from 'react';
import { appStoreContext } from '../../../stores/context.store';
import { Button } from '../button';

export const LogoutButton: FC = () => {
  const AppStore = useContext(appStoreContext);

  const logout = (): void => {
    AppStore.user.logout();
  };

  return (
    <Button
      onClick={logout}
      size="sm"
    >
      Выйти
    </Button>
  );
};
