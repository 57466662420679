import { ReactElement } from 'react';
import toast from 'react-hot-toast';
import copy from 'copy-to-clipboard';
import Dropdown from 'rc-dropdown';
import { formatDate } from '../../../utils';
import { CopyIcon } from '../../components/icons/copy';
import { DotsIcon } from '../../components/icons/dots';
import { UserAvatarIcon } from '../../components/icons/user-avatar';
import { ITableColumn } from '../../components/table';
import { DropdownButton } from './users-list.button';
import { Buttons } from './users-list.button-list';
import { ITableUsersData, IIconsLink } from './users-list.models';

const onCopyData = (data: string): void => {
  toast('Данные успешно скопированны в буфер-обмена.', {
    duration: 4000,
    position: 'bottom-right',
    icon: '✅',
    iconTheme: {
      primary: '#000',
      secondary: '#fff',
    },
    ariaProps: {
      role: 'status',
      'aria-live': 'polite',
    },
  });
  copy(data);
};

export const UsersListColumns: ITableColumn<ITableUsersData>[] = [
  {
    name: 'ФИО пользователя',
    value: (data: ITableUsersData): ReactElement => {
      const { name, sur_name, patronymic } = data.user_info.passport;
      return (
        <div className="users-table__avatar-cell">
          <div className="users-table__avatar-cell__icon-wrapper">
            <UserAvatarIcon className="users-table__avatar-cell__icon" />
          </div>
          <div className="users-table__avatar-cell__info">
            <span className="users-table__avatar-cell__fullName" onClick={(): void => onCopyData(sur_name)}>
              {`${sur_name} `}
            </span>
            <span className="users-table__avatar-cell__fullName" onClick={(): void => onCopyData(name)}>
              {`${name} `}
            </span>
            <span className="users-table__avatar-cell__fullName" onClick={(): void => onCopyData(patronymic)}>
              {`${patronymic} `}
            </span>
            <p className="users-table__avatar-cell__id" onClick={(): void => onCopyData(data.id)}>ID: {data.id}</p>
          </div>
        </div>
      );
    },
  },
  {
    name: 'E-mail',
    value: (data: ITableUsersData): ReactElement => (
      <div className="users-table__email-cell">
        <span className="users-table__email-cell__text">{data.email}</span>
        <span className="users-table__avatar-cell__copy-icon" onClick={(): void => onCopyData(data.email)}>
          <CopyIcon />
        </span>
      </div>
    ),
  },
  {
    name: 'Телефон',
    value: (data: ITableUsersData): ReactElement => (
      <div className="" >{data.phone}
        <span className="users-table__avatar-cell__copy-icon" onClick={(): void => onCopyData(data.phone)}>
          <CopyIcon />
        </span>
      </div>
    ),
  },
  {
    name: 'Документ',
    value: ({ user_info: { docs } }: ITableUsersData): ReactElement => (
      <div className="users-table__avatar-cell__info" onClick={(): void => onCopyData(docs.snils || docs.id_doc)}>
        { !docs.snils && docs.id_doc_name
          ? (
            <>
              <span className="users-table__avatar-cell__fullName">{docs.id_doc_name}</span>
              <p className="users-table__avatar-cell__id">ID: {docs.id_doc}</p>
            </>
          )
          : docs.snils
        }
      </div>
    ),
  },
  {
    name: 'Дата создания',
    value: (data: ITableUsersData): ReactElement => (
      <div className="users-table__created-at-cell" onClick={(): void => onCopyData(formatDate(data.created_at))}>
        {formatDate(data.created_at)}
      </div>
    ),
  },
  {
    name: 'Дата изменения',
    value: (data: ITableUsersData): ReactElement => (
      <div className="users-table__updated-at-cell" onClick={(): void => onCopyData(formatDate(data.updated_at))}>
        {formatDate(data.updated_at)}
      </div>
    ),
  },
  {
    name: '',
    value: ({ id, onEmailClick, onSmsClick }: ITableUsersData): ReactElement => {
      const arrayButtons = Buttons(id, onEmailClick, onSmsClick);
      const userLink = arrayButtons.map((item: IIconsLink) => (<DropdownButton {...item} key={item.id} />));

      return (
        <div className="users-table__controls">
          <Dropdown
            trigger={['click']}
            overlay={<div style={{ padding: '6px' }}>{userLink}</div>}
            animation="slide-up"
            placement="bottomLeft"
            minOverlayWidthMatchTrigger
          >
            <div className="users-table__controls-button">
              <DotsIcon />
            </div>
          </Dropdown>
        </div>
      );
    },
  },
];
