
export const logActionsConstants = {
  put_block_update: 'Обновление блока компетенций',
  delete_block_delete: 'Удаление блока компетенций',
  get_competency: 'Получение списка блока компетенций',
  post_competency: 'Создание компетенций',
  'get_list-competencies': 'Получение списка компетенций',
  patch_competency_archive: 'Архивирование блока',
  patch_competency_publish: 'Публикация блока',
  get_competency_filter: 'Поиск компетенций по фильтру',
  post_competency_new: 'Создание компетенции',
  post_competency_duplicate: 'Создание дубликата компетенции',
  delete_competency_delete: 'Удалить компетенцию',
  put_competency_update: 'Обновить компетенцию',
  patch_competency_edit: 'Редактирование опубликованной компетенции',
  get_course: 'Получение курса по ID',
  post_course: 'Создание курса в статусе "Черновик"',
  post_course_moderate: 'Создание курса в статусе "Модерация"',
  delete_course: 'Удаление курса',
  patch_course: 'Обновление курса',
  get_courses: 'Поиск курсов с фильтрацией',
  get_moderate_course: 'Получение курса с модерации по ID',
  get_moderate_courses: 'Получение списка курсов на модерации с фильтрацией',
  patch_course_moderate: 'Отправка курса на модерацию',
  delete_moderate_course: 'Удаление курса с модерации',
  patch_moderate_course_update: 'Обновление курса на модерации',
  delete_block_published: 'Удаление блока',
  delete_sphere_delete: 'Удаление сферы',
  delete_competency_published: 'Удаление компетенции',
  get_dictionary_list: 'Получение списка словарей',
  get_models: 'Получение списка моделей',
  get_model: 'Получение модели по ID',
  get_organization_account_get: 'Получение аккаунт менеджера',
  patch_organization_account_create: 'Добавление аккаунт менеджера',
  patch_organization_account_delete: 'Удаление аккаунь менеджера',
  post_organization_account_feedback: 'Отзыв на аккаунт менеджера',
  get_moderate_organizations: 'Получить список организаций для аккаунт менеджера с количеством курсов',
  get_organization: 'Получение организации по ID',
  post_organization: 'Создание организации',
  patch_organizations: 'Обновление организации',
  delete_organizations: 'Удаление организации',
  post_organizations_user: 'Добавление юзера в организацию',
  'patch_organizations_replace-users': 'Обновление юзера организации',
  get_organizations_users: 'Получение юзеров организации',
  get_organizations_testers: 'Получение тестеров организации',
  get_questions: 'Получить вопрос по ID',
  get_questions_findby: 'Найти список вопросов по фильтру',
  post_question: 'Создание вопроса',
  post_questions_duplicate: 'Создать дубликат вопроса',
  patch_question: 'Изменить вопрос',
  delete_question: 'Удалить вопрос',
  patch_questions_published: 'Обновление опубликованного вопроса',
  patch_question_archive: 'Архивирование вопроса',
  patch_question_restore: 'Восстановление вопроса',
  patch_question_publish: 'Публикация вопроса',
  post_questions_case: 'Создание вопроса типа case',
  post_questions_matrix: 'Создание вопроса типа matrix',
  post_sphera_new: 'Создание сферы',
  post_sphera_duplicate: 'Дублирование сферы по ID',
  delete_sphera_delete: 'Удаление сферы по ID',
  put_sphera_update: 'Обновление сферы по ID',
  get_sphera_list: 'Получение списка сфер',
  post_upload_files: 'Загрузка файлов',
  'post_user_delete-course': 'Удаление курса тестера',
  get_user_courses: 'Получение курсов пользователя',
  'patch_user_course-activity': 'Активация/деактивация курса тестируемого',
  get_users_dashboard: 'Получение дашборда',
  get_user_course: 'Получение курса пользователя',
  get_course_testers: 'Получение тестеров курса',
  'get_course_assign-tester': 'Регистрация тестируемого на курс',
  get_courses_tester: 'Получение курсов тестируемого',
  patch_course_testers: 'Обновление тестируемых курса',
  post_user_login: 'Авторизация',
  get_user: 'Получение пользователя по ID',
  'get_users_procto-results': 'Получение результатов прокторинга',
  get_users_search: 'Поиск пользователей',
  'get_oneid-users_search': 'Поиск пользователей в oneid',
  'get_proctoring-result': 'Получение результатов прокторинга',
  'get_get-user-results': 'Получение результатов прокторинга пользователя',
  'post_send-account-manager': 'Отправка письма аккаунт менеджеру(курс на модерации)',
  'post_send-days': 'Отправка пиьсма с напоминанием о количестве дней',
  'post_send-moderate': 'Отправка пиьсма о том, что курс на модерации',
  'post_send-attempt': 'Отправка письма с напоминание о количестве попыток',
  'post_send-inactive': 'Отправка письма в случае неактивности',
  post_rolen_new: 'Добавление новой роли пользователю',
  get_role_list: 'Получение всех пользователей ассесмента',
  get_users_get_all: 'Получение всех пользователей ассесмента',
  get_users_all: 'Получение всех пользователей ассесмента',
  post_add_new_user: 'Добавление нового пользователя',
  delete_user_id: 'Удаление юзера',
  post_proctors_token: 'Генерация внешнего токена(прокторинг)',
  'post_proctors_procto-external': 'Добавление результатов прокторинга',
  get_dictionaries: 'Получение справочников',
  post_models: 'Создание модели',
  'get_models_get-by-id': 'Получение модели',
  'put_models_update-by-id': 'Изменение модели',
  'put_models_publish-by-id': 'Публикация модели',
  put_builder_updates: 'Уведомление от билдера',
  get_moderation_course: 'Данные модерации курса',
  post_moderation_course: 'Добавление документа модерации',
  delete_moderation_course: 'Удаление документа модерации',
  read_course_results: 'Чтение результатов курса',
  click_variant_answer: 'Клик варианта ответа',
  click_create_course: 'Клик создания курса',
  click_start_testing: 'Клик старта тестирования',
  click_number_task: 'Клик номера задания',
  click_continue_testing: 'Клик продолжения тестирования',
  click_next_answer: 'Клик следующиего ответа',
  click_finish_testing: 'Клик окончания тестирования',
  click_button_modal: 'Клик кнопки модального окна',
  click_watch_result: 'Клик просмотра ответов',
  click_close_testing: 'Клик закрытия тестирования',
  click_button_close_modal: 'Клик закрытия модального окна',
  click_create_registry: 'Клик на создание реестра',
  click_open_picture_task: 'Клик открытия картинки задания',
  click_open_picture_answer: 'Клик открытия картинки ответа',
  click_prev_answer: 'Клик предыдущего ответа',
  test_actions: 'Проверка действий',
  test_front_actions: 'Проверка действий фронтенда',
  read_user_results: 'Чтение результатов юзера',
  click_back_answer: 'Клик назад ответа',
  finish_session: 'Окончание сессии',
  generate_session: 'Генерация сессии',
  get_certificates: 'Получение списка сертификатов',
  get_tags: 'Привязка тега',
  assign_tester: 'Регистрация на курс',
  update_session: 'Открытие вопроса или ответ на вопрос',
  post_question_feedback: 'Отправка фидбека на вопрос',
};

export const logActionsOneIdConstants = {
  add_user: 'Регистрация пользователя',
  login_psw: 'Авторизация пользователя',
  logout: 'Выход из системы',
  login_phone_email: 'Запрос кода для входа',
  confirm_by_code: 'Верификация по коду',
  set_phone_email: 'Сохранение публичной информации о пользователе',
  upd_psw: 'Обновление пароля',
  upload_file: 'Загрузка изображения',
  upd_phone: 'Обновление номера телефона',
  confirm_phone_profile: 'Подтверждение номера телефона профиля',
  upd_email: 'Изменение электронного ящика профиля',
  confirm_email_profile: 'Подтверждение электронного ящика профиля',
  send_sms_code: 'Отправка смс с кодом на номера телефона',
  send_email_code: 'Отправка письма с кодом на email',
  recovery_psw_req: 'Запрос на восстановление пароля  по номеру телефона',
  recovery_psw_check: 'Проверка токена восстановления пароля',
  recovery_psw_save: 'Сохранение нового пароля при восстановлении',
  upload_doc: 'Загрузка документов',
  delete_doc: 'Удаление документов',
  upd_doc: 'Обновление документов',
  bind_user_leader_id: 'Привязка лидер id',
  bind_callback_leader_id: 'Обратная отправка в leader id при привязке',
  login_leader_id: 'Авторизация через leaderid',
  login_callback_leader_id: 'Обратная отправка в leader id при авторизации',
  logout_leader_id: 'Выход из аккаунта leader id',
};
