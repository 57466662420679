import { makeAutoObservable, runInAction } from 'mobx';
import { ILogsList } from '../models/entities/logs.models';
import { IRootStore } from '../models/stores/root.models';
import { IGetUserLogsItemParams, IUserLogsItemStore } from '../models/stores/user-log-item.models';
import { SupportBackendRoutes } from '../routes/backend.routes';

export class UserLogsItemStore implements IUserLogsItemStore {

  public readonly rootStore: IRootStore;

  public logsItem: ILogsList | null = null;
  public loading: boolean = false;

  constructor(rootStore: IRootStore) {
    this.rootStore = rootStore;

    makeAutoObservable(this, {
      rootStore: false,
    });
  }

  public async getLogsItem(request: IGetUserLogsItemParams): Promise<void> {
    try {
      runInAction(() => {
        this.logsItem = null;
        this.loading = true;
      });

      const path = SupportBackendRoutes.getUserLogsItem.replace(':userId', request.userId).replace(':logId', request.logId);

      const response = await this.rootStore.requests.json<ILogsList>({
        method: 'GET',
        baseURL: this.rootStore.config.supportAPI,
        route: path,
        token: this.rootStore.user.token,
      });

      runInAction(() => {
        this.logsItem = response;
        this.loading = false;
      });
    }
    catch (error) {
      runInAction(() => {
        this.logsItem = null;
        this.loading = false;
        this.rootStore.errors.setError(error);
      });
    }
  }

}
