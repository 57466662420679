import { FC, Fragment } from 'react';
import { Link } from 'react-router-dom';
import cn from 'classnames';
import { IBreadcrumbsProps } from './breadcrumbs.models';
import './breadcrumbs.styles.scss';

export const Breadcrumbs: FC<IBreadcrumbsProps> = (props) => {
  const {
    className,
    routes,
    ...other
  } = props;
  const rootClasses = {
    base: 'breadcrumbs',
  };
  const rootCN = cn(rootClasses.base, className);

  return (
    <div className={rootCN} {...other}>
      {routes.map((route, index): JSX.Element => {
        if (route.link) {
          return (
            <Fragment key={index}>
              {route.link}
            </Fragment>
          );
        }

        return (
          <Link
            to={route.to}
            className="breadcrumbs__link"
            key={index}
          >
            {route.name}
          </Link>
        );
      })}
    </div>
  );
};
