import { FC, useContext, useEffect } from 'react';
import { observer } from 'mobx-react';
import Dialog from 'rc-dialog';
import Tabs from 'rc-tabs';
import { logActionsConstants, logActionsOneIdConstants } from '../../../constants/log-actions.constants';
import { appStoreContext } from '../../../stores/context.store';
import { formatDate } from '../../../utils';
import { CloseIcon } from '../../components/icons/close';
import { Loader } from '../../components/loader';
import { ILogDialog } from './log-dialog.models';
import { getLogDialogTabs } from './log-dialog.tabs';
import 'rc-dialog/assets/index.css';
import './log-dialog.styles.scss';

export const LogDialog: FC<ILogDialog> = observer(({ ...props }) => {
  const AppStore = useContext(appStoreContext);
  const { logsItem, loading } = AppStore.userLogsItem;
  const allActions = { ...logActionsConstants, ...logActionsOneIdConstants };
  const action = logsItem?.action ? allActions[logsItem.action] || logsItem.action : '';
  const time = logsItem?.event_date_time ? formatDate(logsItem.event_date_time, 'dd.MM.yyyy, k:mm') : '';
  const title = loading ? 'Загрузка...' : `${action} ${time}`;
  const logDialogTabs = logsItem ? getLogDialogTabs(logsItem) : null;

  useEffect(() => {

  }, [logsItem]);

  return (
    <Dialog
      className="log-dialog"
      title={title}
      closeIcon={<CloseIcon />}
      rootClassName="root-log-dialog"
      {...props}
    >
      {loading && (
        <div className="log-dialog__content">
          <Loader />
        </div>
      )}
      {!loading && logDialogTabs && (
        <Tabs
          className="log-dialog-tabs"
          items={logDialogTabs}
        />
      )}
      {!loading && !logDialogTabs && (
        <div className="log-dialog__content">
          <span className="log-dialog__content-title">
            Empty
          </span>
        </div>
      )}
    </Dialog>
  );
});
