import { makeAutoObservable, runInAction } from 'mobx';
import { IUser } from '../models/entities/user.models';
import { IGetUsersByKeyResponse } from '../models/routers/user.models';
import { IRootStore } from '../models/stores/root.models';
import { ISearchByKeyParams, ISearchUsersByKeyStore } from '../models/stores/users-find.models';
import { SupportBackendRoutes } from '../routes/backend.routes';

export class SearchUsersByKeyStore implements ISearchUsersByKeyStore {

  public readonly rootStore: IRootStore;

  public data: IUser[] | null = null;
  public total: number = 0;
  public loading: boolean = false;

  constructor(rootStore: IRootStore) {
    this.rootStore = rootStore;

    makeAutoObservable(this, {
      rootStore: false,
    });
  }

  public reset(): void {
    this.data = null;
    this.total = 0;
  }

  public async get(request: ISearchByKeyParams): Promise<void> {
    try {
      runInAction(() => {
        this.reset();
        this.loading = true;
      });

      const response = await this.rootStore.requests.json<IGetUsersByKeyResponse>({
        method: 'GET',
        baseURL: this.rootStore.config.supportAPI,
        route: SupportBackendRoutes.findUserByKey,
        token: this.rootStore.user.token,
        params: {
          id: request.id,
          page: request.page,
          limit: request.limit,
          email: request.email,
          phone: request.phone,
          snils: request.snils,
          id_doc: request.docId,
          name: request.name,
          sur_name: request.surName,
          patronymic: request.patronymic,
        },
      });

      runInAction(() => {
        this.loading = false;
        this.total = response.count;
        this.data = response.users;
      });
    }
    catch (error) {
      runInAction(() => {
        this.reset();
        this.loading = false;
        this.rootStore.errors.setError(error);
      });
    }
  }

}
