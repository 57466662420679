import { FC, useContext, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { observer } from 'mobx-react';
import { appStoreContext } from '../../../stores/context.store';
import { formatDate } from '../../../utils';
import { MailIcon } from '../../components/icons/mail';
import './user-codes.styles.scss';

export const UserCodes: FC = observer(() => {
  const AppStore = useContext(appStoreContext);
  const { codeItemsInfo } = AppStore.userCodes;

  const codes = {
    email: 'E-mail',
    sms: 'СМС',
  };

  const { user_id } = useParams();

  useEffect(() => {
    if (typeof user_id === 'string' && user_id.length > 0) {
      AppStore.userCodes.getUserCodesInfo({ userId: user_id });
    }
  }, [user_id]);

  return (
    <>
      <div className="user-codes">
        {codeItemsInfo?.map((item) => (
          <div className="item" key={item.type}>
            <div className="column">
              <div className="icon blue"><MailIcon /></div>
              <div className="text">{codes[item.type]}</div>
            </div>
            <div className="column">
              <div className="title">Дата:</div>
              <div className="text">{formatDate(item.event_date_time, 'dd.MM.yy')}</div>
            </div>
            <div className="column">
              <div className="title">Время:</div>
              <div className="text">{formatDate(item.event_date_time, 'HH:mm')}</div>
            </div>
          </div>
        ))}
      </div>
    </>
  );
});
