import { FC } from 'react';
import { NavLink } from 'react-router-dom';
import { BrowserRoute } from '../../../routes/browser.routes';
import { HeaderProfile } from '../../content/header-profile';
import { LogoInnopolisUnione } from '../icons/logo-innopolis-unione';
import './header.styles.scss';

export const Header: FC = () => (
  <header className="header">
    <NavLink to={BrowserRoute.index}>
      <LogoInnopolisUnione />
    </NavLink>

    <HeaderProfile />
  </header>
);
