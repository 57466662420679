import { FC } from 'react';
import RCTooltip from 'rc-tooltip';
import { InfoIcon } from '../icons/info';
import { ITooltip } from './tooltip.models';
import 'rc-tooltip/assets/bootstrap.css';
import './tooltip.styles.scss';

export const Tooltip: FC<ITooltip> = (props) => {
  const {
    placement = 'right',
    trigger = ['hover'],
    children = <InfoIcon className="tooltip__icon" />,
    ...other
  } = props;

  return (
    <RCTooltip
      trigger={trigger}
      placement={placement}
      {...other}
    >
      <span className="tooltip__content">
        {children}
      </span>
    </RCTooltip>
  );
};
