import { RefObject, useEffect } from 'react';

type EventName = keyof DocumentEventMap;

export const useClickOutside = (
  ref: RefObject<HTMLElement>,
  callback: () => void,
  events: EventName[] = ['click'],
): void => {
  useEffect(() => {
    const handleClick = (e: Event): void | (() => void) => {
      if (ref.current && !ref.current.contains(e.target as Node)) {
        return callback();
      }
      return undefined;
    };

    for (const eventName of events) {
      document.addEventListener(eventName, handleClick);
    }

    return () => {
      for (const eventName of events) {
        document.removeEventListener(eventName, handleClick);
      }
    };
  }, [ref, callback, events]);
};
