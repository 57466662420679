import { FC, ReactNode, useContext, useEffect } from 'react';
import { Form, Field } from 'react-final-form';
import { useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react';
import { ILoginRequest } from '../../../models/entities/login.models';
import { BrowserRoute } from '../../../routes/browser.routes';
import { emailValidator } from '../../../shared/validators/email.validators';
import { passwordValidator } from '../../../shared/validators/password.validator';
import { appStoreContext } from '../../../stores/context.store';
import { Button } from '../../components/button';
import { Input } from '../../components/input';
import './login.styles.scss';

export const Login: FC = observer(() => {
  const appStore = useContext(appStoreContext);

  const navigate = useNavigate();

  useEffect(() => {
    if (appStore.user.token) {
      navigate(BrowserRoute.users, {
        replace: true,
      });
    }
  }, [appStore.user.token]);

  const onSubmit = (values: ILoginRequest): void => {
    appStore.user.login(values);
  };

  return (
    <div className="login">
      <Form
        onSubmit={onSubmit}
        render={(props): ReactNode => (
          <div className="login__block">
            <h1 className="login__title">
              Авторизация
            </h1>
            <form
              className="login__form"
              onSubmit={props.handleSubmit}
            >
              <Field
                name="email"
                validate={emailValidator}
                render={(field): ReactNode => (
                  <div className="login__field">
                    <Input
                      state="default"
                      type="text"
                      placeholder="Введите почту"
                      {...field.input}
                    />
                    {(field.meta.error && field.meta.touched) &&
                      <span className="login__error">
                        {field.meta.error}
                      </span>
                    }
                  </div>
                )}
              />
              <Field
                name="password"
                validate={passwordValidator}
                render={(field): ReactNode => (
                  <div className="login__field">
                    <Input
                      state="default"
                      type="password"
                      placeholder="Введите пароль"
                      {...field.input}
                    />
                    {(field.meta.error && field.meta.touched) &&
                      <span className="login__error">
                        {field.meta.error}
                      </span>
                    }
                  </div>
                )}
              />
              <Button
                type="submit"
                size="sm"
              >
                Войти
              </Button>
            </form>
          </div>
        )}
      />
    </div>
  );
});
