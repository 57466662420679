import { makeAutoObservable } from 'mobx';
import { IErrorsStore } from '../models/stores/errors.store';

export class ErrorsStore implements IErrorsStore {

  public id?: number | undefined = undefined;
  public message?: string | undefined = undefined;

  constructor() {
    makeAutoObservable(this);
  }

  public setError(error: unknown): void {
    this.id = Date.now();
    this.message = error instanceof Error ? error.message : String(error);
  }

}
