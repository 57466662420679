import { FC, SVGProps } from 'react';

export const MessageIcon: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="14"
    height="12"
    viewBox="0 0 14 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M1.59247 10.0875L5 5.99991M12.4088 10.0862L9 5.99991M1.01919 3.33325L5.83666 6.54825C6.26355 6.832 6.47699 6.97388 6.70761 7.02901C6.91141 7.07774 7.12383 7.07774 7.32764 7.02901C7.55825 6.97388 7.7717 6.832 8.19858 6.54825L12.9808 3.33325M4.2 10.6666H9.8C10.9201 10.6666 11.4802 10.6666 11.908 10.4486C12.2843 10.2569 12.5903 9.95089 12.782 9.57457C13 9.14674 13 8.58669 13 7.46659V4.53325C13 3.41315 13 2.85309 12.782 2.42527C12.5903 2.04895 12.2843 1.74299 11.908 1.55124C11.4802 1.33325 10.9201 1.33325 9.8 1.33325H4.2C3.0799 1.33325 2.51984 1.33325 2.09202 1.55124C1.71569 1.74299 1.40973 2.04895 1.21799 2.42527C1 2.85309 1 3.41315 1 4.53325V7.46659C1 8.58669 1 9.14674 1.21799 9.57457C1.40973 9.95089 1.71569 10.2569 2.09202 10.4486C2.51984 10.6666 3.0799 10.6666 4.2 10.6666Z"
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
