import { FC, SVGProps } from 'react';

export const EmailIcon: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M10.6006 11.8C9.59769 12.5535 8.35099 13 7 13C3.68629 13 1 10.3137 1 7C1 3.68629 3.68629 1 7 1C10.3137 1 13 3.68629 13 7V8C13 8.92047 12.2538 9.66667 11.3333 9.66667C10.4129 9.66667 9.66667 8.92047 9.66667 8V4.33333M9.66667 7C9.66667 8.47276 8.47276 9.66667 7 9.66667C5.52724 9.66667 4.33333 8.47276 4.33333 7C4.33333 5.52724 5.52724 4.33333 7 4.33333C8.47276 4.33333 9.66667 5.52724 9.66667 7Z"
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
