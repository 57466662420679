import React, { HTMLAttributes, ReactElement, ReactNode } from 'react';
import cn from 'classnames';
import { ITableData, ITableProps } from './table.models';
import './table.styles.scss';

export const Table = <TData extends ITableData>(
  props: ITableProps<TData>,
): ReactElement => {
  const {
    data,
    className,
    headerRowRender,
    rowRender,
    columns,
    ...other
  } = props;

  const rootCN = cn('table', className);

  return (
    <div {...other} className={rootCN}>
      <div className="table__header">
        {columns.map((col, index) => {
          const colProps = {
            className: 'table__header-column',
            children: col.name,
          };
          if (headerRowRender) {
            return (
              <React.Fragment key={index}>
                {headerRowRender(col, colProps)}
              </React.Fragment>
            );
          }

          return <div key={index} onClick={col.onClick} {...colProps} />;
        })}
      </div>
      <div className="table__content">
        {data &&
          data.map((row) => {
            const rowProps: HTMLAttributes<HTMLElement> = {
              className: 'table__content-row',
            };
            const rowColumns = columns.map<ReactNode>((col, index) => (
              <div className="table__content-column" key={index}>
                {col.value && col.value(row)}
              </div>
            ));

            if (rowRender) {
              return rowRender(row, rowProps, rowColumns);
            }

            return (
              <div {...rowProps} key={row._id}>
                {rowColumns}
              </div>
            );
          })}
      </div>
    </div>
  );
};
