import { FC } from 'react';
import { PageTitle } from '../../components/page-title';
import { Tooltip } from '../../components/tooltip';
import { UsersList } from '../../content/users-list';
import './users.styles.scss';

export const Users: FC = () => (
  <div className="users-page">
    <PageTitle>
      Список пользователей
      <Tooltip overlay={
        <span>
          Здесь вы можете воспользоваться поиском и фильтрами для выбора нужного пользователя
        </span>
      } />
    </PageTitle>
    <UsersList />
  </div>
);
