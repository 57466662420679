export enum BrowserRoute {
  index = '/',
  user = '/user/:user_id',
  userLogs = '/user/:user_id/logs',
  workspace = '/user/:user_id/workspace',
  organizations = '/user/:user_id/organizations',
  codes = '/user/:user_id/codes',
  profile = '/user/:user_id/profile',
  users = '/users',
  login = '/login',
  notFound = '/not-found',
}
