import reactHotToast, { ToastOptions } from 'react-hot-toast';
import cn from 'classnames';
import { CloseIcon } from '../icons/close';
import { INotificationProps, ToastTypes } from './notification.models';
import './notification.styles.scss';

export const toast = ({
  text,
  opts,
  type = ToastTypes.Default,
  loadingBar,
}: INotificationProps): void => {
  const toastClasses = {
    base: 'toast',
    type: {
      default: 'toast_default',
      warning: 'toast_warning',
      success: 'toast_success',
      error: 'toast_error',
    },
  };
  const toastCN = cn(
    toastClasses.base,
    opts?.className,
    {
      [toastClasses.type[type]]: !!type,
    },
  );
  const toastLoadingProgressClasses = {
    base: 'toast__progress',
    type: {
      default: 'toast__progress_default',
      warning: 'toast__progress_warning',
      success: 'toast__progress_success',
      error: 'toast__progress_error',
    },
  };
  const toastLoadingProgressCN = cn(
    toastLoadingProgressClasses.base,
    {
      [toastLoadingProgressClasses.type[type]]: !!type,
    },
  );


  const options: ToastOptions = {
    duration: 5000,
    position: 'top-right',
    ...opts,
    className: toastCN,
  };

  reactHotToast(
    (t) => {
      const loadingProgressDuration = t.duration ? t.duration : 0;
      const loadingBarIsVisible = !!(loadingBar && loadingProgressDuration);
      const loadingBarDisplay = loadingBarIsVisible ? 'flex' : 'none';

      return (
        <div className="toast__wrapper">
          <p className="toast__text">
            {text}
          </p>
          {loadingBarDisplay &&
            <div
              className={toastLoadingProgressCN}
              style={{
                animationDuration: `${loadingProgressDuration}ms`,
              }}
            />
          }
          <CloseIcon
            className="toast__dismiss-icon"
            onClick={(): void => reactHotToast.dismiss(t.id)}
          />
        </div>
      );
    },
    options,
  );
};
