import { FC, HTMLProps, useCallback, useRef, useState } from 'react';
import cn from 'classnames';
import { ChevronsIcon } from '../icons/chevrons';
import { ISelectOption, ISelectProps } from './select.models';
import './select.styles.scss';

export const Select: FC<ISelectProps> = ({
  icon,
  options,
  defaultValue,
  size = 'md',
  label,
  className,
  dropTop = false,
  dropRight = false,
  error,
  disabled,
  withShadow = true,
  onBlur,
  onChange,
  ...restProps
}) => {
  const wrapperRef = useRef<HTMLDivElement>(null);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const getOptionLabel = useCallback((option: ISelectOption): string => {
    if (typeof option === 'string') {
      return option;
    }
    if (typeof option === 'number') {
      return option.toString();
    }
    return option.label;
  }, []);

  const [selected, setSelected] = useState<ISelectOption>(defaultValue ?? options[0]);
  const [isOpen, setOpen] = useState<boolean>(false);

  const selectedLabel = getOptionLabel(selected);

  const wrapperProps: HTMLProps<HTMLDivElement> = {
    ...restProps,
    ref: wrapperRef,
    tabIndex: 1,
    onBlur: (event) => {
      setOpen(false);

      if (typeof onBlur === 'function') {
        onBlur(event);
      }
    },
  };

  const toggleDropdown = (): void => {
    setOpen((prev) => !prev);
  };

  const onOptionChange = (option: ISelectOption): void => {
    setSelected(option);

    if (typeof onChange === 'function') {
      onChange(option);
    }
  };

  const selectWrapperClasses = {
    base: 'select__wrapper',
    withShadow: 'select__wrapper_withShadow',
  };
  const selectWrapperCN = cn(
    selectWrapperClasses.base,
    {
      [selectWrapperClasses.withShadow]: withShadow,
    },
    className,
  );

  const selectedLabelClasses = {
    base: 'select__selected-label',
    disabled: 'select__selected-label_disabled',
    error: 'select__selected-label_error',
    size: {
      md: 'select__selected-label_md',
      sm: 'select__selected-label_sm',
    },
  };

  const selectedLabelCN = cn(
    selectedLabelClasses.base,
    {
      [selectedLabelClasses.error]: error,
      [selectedLabelClasses.disabled]: disabled,
      [selectedLabelClasses.size[size]]: !!size,
    },
  );

  const dropDownClasses = {
    base: 'select__dropdown',
    visible: 'select__dropdown_visible',
    invisible: 'select__dropdown_invisible',
    dropPos: {
      top: 'select__dropdown_dropTop',
      left: 'select__dropdown_dropLeft',
      right: 'select__dropdown_dropRight',
      bottom: 'select__dropdown_dropBottom',
    },
  };

  const dropDownCN = cn(
    dropDownClasses.base,
    {
      [dropDownClasses.dropPos.top]: dropTop,
      [dropDownClasses.dropPos.bottom]: !dropTop,
      [dropDownClasses.dropPos.right]: dropRight,
      [dropDownClasses.dropPos.left]: !dropRight,
      [dropDownClasses.visible]: isOpen,
      [dropDownClasses.invisible]: !isOpen,
    },
  );

  const iconClasses = {
    base: 'select__icon',
    size: {
      sm: 'select__icon_sm',
      md: 'select__icon_md',
    },
    rotate: 'select__icon_rotate-180',
  };

  const iconCN = cn(
    iconClasses.base,
    {
      [iconClasses.size[size]]: !!size,
      [iconClasses.rotate]: isOpen,
    },
  );

  return (
    <div {...wrapperProps} className={selectWrapperCN}>
      {label &&
        <label className="select__label">
          {label}
        </label>
      }
      <div
        className="select"
        onClick={toggleDropdown}
      >
        <div className={selectedLabelCN}>
          <span className="select__selected-option-label">{selectedLabel}</span>
          {icon ?? <ChevronsIcon className={iconCN} />}
        </div>
        <div ref={dropdownRef} className={dropDownCN}>
          <div className="select__dropdown__content">
            {options.map((option) => {
              const optionLabel = getOptionLabel(option);
              const isCurrent = optionLabel === selectedLabel;

              const optionClasses = cn(
                'select__option',
                { select__option_active: isCurrent },
              );

              const onOptionClick = (): void => {
                onOptionChange(option);
              };

              return (
                <div
                  key={optionLabel}
                  className={optionClasses}
                  onClick={onOptionClick}
                >
                  {optionLabel}
                </div>
              );
            })}
          </div>
        </div>
      </div>
      {error &&
        <div className="select__error-text">
          {error}
        </div>
      }
    </div>
  );
};
