import { ReactElement } from 'react';
import { ITableLogsData } from '../logs-list.models';

export const DurationCell = (data: ITableLogsData): ReactElement => {
  const duration = `${data.duration / 1000} сек`;

  return (
    <div className="logs-table__duration-cell">
      {duration}
    </div>
  );
};
