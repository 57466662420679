import { BrowserRoute } from '../../../routes/browser.routes';
import { EmailIcon } from '../../components/icons/email-icon';
import { EyeIcon } from '../../components/icons/eye';
import { LogsIcon } from '../../components/icons/logs';
import { MessageIcon } from '../../components/icons/message';
import { ProfileIcon } from '../../components/icons/profile';
import { getRouteWithParams } from '../../routes/get-route-params';
import { TabNames } from '../user-tabs';
import { IIconsLink } from './users-list.models';

export const Buttons = (
  id: string,
  onEmailClick?: ((id: string) => void),
  onSmsClick?: ((id: string) => void),
): IIconsLink[] => [
  {
    icon: <EyeIcon />,
    name: 'Рабочий стол',
    className: 'users-table__controls-button',
    tabs: TabNames.WORKSPACE,
    route: getRouteWithParams(BrowserRoute.workspace, { user_id: id }),
    id: '1',
  },
  {
    icon: <ProfileIcon />,
    name: 'Профиль',
    className: 'users-table__controls-button',
    tabs: TabNames.PROFILE,
    route: getRouteWithParams(BrowserRoute.profile, { user_id: id }),
    id: '2',
  },
  {
    icon: <LogsIcon />,
    name: 'Логи',
    className: 'users-table__controls-button',
    tabs: TabNames.LOGS,
    route: getRouteWithParams(BrowserRoute.userLogs, { user_id: id }),
    id: '3',
  },
  {
    icon: <MessageIcon />,
    name: 'Отправить код для входа в unione',
    className: 'users-table__controls-button',
    tabs: TabNames.LOGS,
    onHandleClick: onSmsClick,
    id: '4',
  },
  {
    icon: <EmailIcon />,
    name: 'Отправить код для подтверждения почты',
    className: 'users-table__controls-button',
    tabs: TabNames.LOGS,
    onHandleClick: onEmailClick,
    id: '5',
  },
];
