import { FC, useMemo } from 'react';
import DatePicker from 'react-datepicker';
import formatISO from 'date-fns/formatISO';
import ru from 'date-fns/locale/ru';
import { HttpStatusesList } from '../../../constants/http-statuses.constants';
import { logActionsConstants, logActionsOneIdConstants } from '../../../constants/log-actions.constants';
import { ServiceDictionary } from '../../../constants/service-dictionary.constants';
import { TLogAction, TServiceDictionary } from '../../../models/entities/logs.models';
import { CloseIcon } from '../../components/icons/close';
import { IMultiselectOption, MultiSelect } from '../../components/multiselect';
import { IOption, ISelectOption, Select } from '../../components/select';
import { ILogsListFiltersProps } from './logs-list.models';
import 'react-datepicker/dist/react-datepicker.css';
import './logs-list.styles.scss';

export const LogsListFilters: FC<ILogsListFiltersProps> = ({
  onServiceChange,
  onActionChange,
  onStartDateChange,
  onEndDateChange,
  onIsSupportChange,
  onHttpStatusChange,
  action,
  isSupport,
  status,
  service,
  startDate,
  endDate,
}) => {
  const startDateValue = useMemo<Date | null>(() => (startDate ? new Date(startDate) : null), [startDate]);
  const endDateValue = useMemo<Date | null>(() => (endDate ? new Date(endDate) : null), [endDate]);

  const assesmentActions: IOption[] = Object.entries(logActionsConstants).map(([value, label]) => ({
    label,
    value,
  }));
  const oneIdActions: IOption[] = Object.entries(logActionsOneIdConstants).map(([value, label]) => ({
    label,
    value,
  }));
  const services: IOption[] = Object.entries(ServiceDictionary).map(([value, label]) => ({
    label,
    value,
  }));
  const httpStatuses: IOption[] = HttpStatusesList.map((httpStatus) => ({
    label: httpStatus.toString(),
    value: httpStatus.toString(),
  }));

  const actionsOptions = useMemo<IOption[]>(() => {
    if (service === 'auth') {
      return [
        {
          label: 'Все действия',
          value: undefined,
        },
        ...oneIdActions,
      ];
    }
    if (service === 'assessment') {
      return [
        {
          label: 'Все действия',
          value: undefined,
        },
        ...assesmentActions,
      ];
    }

    return [
      {
        label: 'Все действия',
        value: undefined,
      },
      ...assesmentActions,
      ...oneIdActions,
    ];
  }, [assesmentActions, oneIdActions, service]);

  const servicesOptions = useMemo<IOption[]>(() => [
    {
      label: 'Сервис',
      value: undefined,
    },
    ...services,
  ], [services]);

  const isSupportOptions = useMemo<IOption[]>(() => [
    {
      label: 'Саппорт',
      value: undefined,
    },
    {
      label: 'Да',
      value: true,
    },
    {
      label: 'Нет',
      value: false,
    },
  ], []);

  const httpStatusesOptions = useMemo<IOption[]>(() => [
    {
      label: 'Статусы',
      value: undefined,
    },
    ...httpStatuses,
  ], [httpStatuses]);

  const defaultServiceValue = useMemo<IOption | undefined>(() => {
    if (typeof service !== 'string' || service.length <= 0) {
      return undefined;
    }

    const defaultOptions = servicesOptions.find((servicesOption) => servicesOption.value === service);

    if (!defaultOptions) {
      return {
        label: ServiceDictionary[service as TServiceDictionary] ?? service,
        value: service,
      };
    }

    return defaultOptions;
  }, [service, servicesOptions]);

  const defaultActionValue = useMemo<IOption[]>(() => {
    if (typeof action !== 'string' || action.length <= 0) {
      return [];
    }

    const actionList = action.split(',');

    const defaultOptions = actionsOptions
      .filter((actionsOption) => actionList.some((item) => item === actionsOption.value));
    const allActions = { ...logActionsConstants, ...logActionsOneIdConstants };

    if (!defaultOptions.length) {
      return [{
        label: allActions[action as TLogAction] ?? action,
        value: action,
      }];
    }

    return defaultOptions;
  }, [action, actionsOptions]);

  const defaultIsSupportValue = useMemo<IOption | undefined>(() => {
    if (typeof isSupport !== 'boolean') {
      return undefined;
    }

    const defaultOptions = isSupportOptions.find((isSupportOption) => isSupportOption.value === isSupport);

    return defaultOptions;
  }, [isSupport, isSupportOptions]);

  const defaultHttpStatusValue = useMemo<IOption | undefined>(() => {
    if (typeof status !== 'string' || status.length <= 0) {
      return undefined;
    }

    const defaultOptions = httpStatusesOptions.find((httpStatusOption) => httpStatusOption.value === status);

    return defaultOptions;
  }, [httpStatusesOptions, status]);

  const onServiceChangeHandler = (option: ISelectOption): void => {
    if (typeof option === 'object' && typeof option.value !== 'boolean') {
      onServiceChange(option.value);
    }
  };

  const onActionsChangeHandler = (options: IMultiselectOption[]): void => {
    const values = options
      .map((option) => option.value)
      .join(',');

    onActionChange(values);
  };

  const onIsSupportChangeHandler = (option: ISelectOption): void => {
    if (typeof option === 'object' && typeof option.value !== 'string') {
      onIsSupportChange(option.value);
    }
  };

  const onHttpStatusChangeHandler = (option: ISelectOption): void => {
    if (typeof option === 'object' && typeof option.value !== 'boolean') {
      onHttpStatusChange(option.value);
    }
  };

  const onStartDateChangeHandler = (date: Date | null): void => {
    if (date instanceof Date) {
      onStartDateChange(
        formatISO(date, { representation: 'date' }),
      );
    }
  };

  const onEndDateChangeHandler = (date: Date | null): void => {
    if (date instanceof Date) {
      onEndDateChange(
        formatISO(date, { representation: 'date' }),
      );
    }
  };

  const resetStartDate = (): void => {
    onStartDateChange(null);
  };

  const resetEndDate = (): void => {
    onEndDateChange(null);
  };

  return (
    <div className="logs-list__filters-form">
      <Select
        className="logs-list__filters-form__field"
        defaultValue={defaultServiceValue}
        options={servicesOptions}
        onChange={onServiceChangeHandler}
      />
      <MultiSelect
        className="logs-list__filters-form__field"
        defaultValue={defaultActionValue}
        options={actionsOptions}
        onChange={onActionsChangeHandler}
      />
      <div className="logs-list__reset">
        <DatePicker
          className="logs-list__filters-form__date-field"
          placeholderText="Дата начала"
          selected={startDateValue}
          onChange={onStartDateChangeHandler}
          dateFormat="dd/MM/yyyy"
          locale={ru}
        />
        {startDateValue &&
          <button
            className="logs-list__reset-button"
            onClick={resetStartDate}
          >
            <CloseIcon width={12} height={12} />
          </button>
        }
      </div>
      <div className="logs-list__reset">
        <DatePicker
          className="logs-list__filters-form__date-field"
          placeholderText="Дата окончания"
          selected={endDateValue}
          onChange={onEndDateChangeHandler}
          dateFormat="dd/MM/yyyy"
          locale={ru}
        />
        {endDateValue &&
          <button
            className="logs-list__reset-button"
            onClick={resetEndDate}
          >
            <CloseIcon width={12} height={12} />
          </button>
        }
      </div>
      <Select
        className="logs-list__filters-form__field"
        defaultValue={defaultIsSupportValue}
        options={isSupportOptions}
        onChange={onIsSupportChangeHandler}
      />
      <Select
        className="logs-list__filters-form__field"
        defaultValue={defaultHttpStatusValue}
        options={httpStatusesOptions}
        onChange={onHttpStatusChangeHandler}
      />
    </div>
  );
};
