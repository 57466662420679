import { ReactElement } from 'react';
import { IpIcon } from '../../../components/icons/ip';
import { ITableLogsData } from '../logs-list.models';

export const IpCell = (data: ITableLogsData): ReactElement => (
  <div className="logs-table__ip-cell">
    <div className="logs-table__ip-cell__icon-wrapper">
      <IpIcon className="logs-table__ip-cell__icon" />
    </div>
    <div className="logs-table__ip-cell__info">
      <p className="logs-table__ip-cell__ip">
        {data.ip}
      </p>
    </div>
  </div>
);
