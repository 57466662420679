import { FC, SVGProps } from 'react';

export const DotsIcon: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 4 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M1.99967 5.99992H2.00634M1.99967 1.99992H2.00634M1.99967 9.99992H2.00634M2.66634 5.99992C2.66634 6.36811 2.36786 6.66659 1.99967 6.66659C1.63148 6.66659 1.33301 6.36811 1.33301 5.99992C1.33301 5.63173 1.63148 5.33325 1.99967 5.33325C2.36786 5.33325 2.66634 5.63173 2.66634 5.99992ZM2.66634 9.99992C2.66634 10.3681 2.36786 10.6666 1.99967 10.6666C1.63148 10.6666 1.33301 10.3681 1.33301 9.99992C1.33301 9.63173 1.63148 9.33325 1.99967 9.33325C2.36786 9.33325 2.66634 9.63173 2.66634 9.99992ZM2.66634 1.99992C2.66634 2.36811 2.36786 2.66659 1.99967 2.66659C1.63148 2.66659 1.33301 2.36811 1.33301 1.99992C1.33301 1.63173 1.63148 1.33325 1.99967 1.33325C2.36786 1.33325 2.66634 1.63173 2.66634 1.99992Z"
      stroke="#6F7C98"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
