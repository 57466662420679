import { FC, Fragment, HTMLAttributes, ReactNode, useMemo } from 'react';
import cn from 'classnames';
import { ILogsList } from '../../../models/entities/logs.models';
import { NotFoundFilesIcon } from '../../components/icons/not-found-files';
import { Loader } from '../../components/loader';

export interface ILogsEmptyProps extends HTMLAttributes<HTMLDivElement> {
  logs: ILogsList[] | null;
  loading?: boolean;
  children?: ReactNode | undefined;
}

export const LogsEmpty: FC<ILogsEmptyProps> = ({
  logs,
  loading,
  ...props
}) => {
  const logsIsEmpty = useMemo(() => (
    !!logs &&
      typeof logs === 'object' &&
      logs.length <= 0
  ), [logs]);

  const rootCN = cn('logs-list-empty', props.className);

  if (loading) {
    return (
      <div className={rootCN} {...props}>
        <div className="users-list-empty__content">
          <Loader />
        </div>
      </div>
    );
  }

  if (!logsIsEmpty) {
    return (
      <Fragment>
        {props.children}
      </Fragment>
    );
  }

  return (
    <div className={rootCN} {...props}>
      <div className="logs-list-empty__content">
        <NotFoundFilesIcon className="logs-list-empty__icon" />
        <h3 className="logs-list-empty__content__title">
          Логи пустые
        </h3>
      </div>
    </div>
  );
};
