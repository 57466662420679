import { makeAutoObservable, runInAction } from 'mobx';
import { IGetUserDataResponse, ILoginRequest, ILoginResponse } from '../models/entities/login.models';
import { IUser } from '../models/entities/user.models';
import { IRootStore } from '../models/stores/root.models';
import { StorageKeys } from '../models/stores/storage.models';
import { IUserStore } from '../models/stores/users.models';
import { AuthBackendRoutes } from '../routes/auth.routes';

export class UserStore implements IUserStore {

  public readonly rootStore: IRootStore;

  public data: IUser | null = null;
  public token?: string = undefined;
  public loading: boolean = false;

  constructor(rootStore: IRootStore) {
    this.rootStore = rootStore;
    this.token = this.getToken();

    makeAutoObservable(this, {
      rootStore: false,
    });
  }


  private getToken(): string | undefined {
    const localToken = this.rootStore.config.localToken;
    if (typeof localToken === 'string' && localToken.length > 0) {
      return localToken;
    }

    const unioneToken = localStorage.getItem(StorageKeys.supportToken);
    if (typeof unioneToken === 'string' && unioneToken.length > 0) {
      localStorage.setItem(StorageKeys.supportToken, unioneToken);
      return unioneToken;
    }

    return undefined;
  }


  public logout(): void {
    this.token = undefined;
    this.data = null;
    localStorage.removeItem(StorageKeys.supportToken);
  }


  public async login(request: ILoginRequest): Promise<void> {
    try {
      runInAction(() => {
        this.logout();
        this.loading = true;
      });

      const response = await this.rootStore.requests.json<ILoginResponse>({
        method: 'POST',
        baseURL: this.rootStore.config.authHost,
        route: AuthBackendRoutes.signin,
        data: request,
      });

      if (typeof response.errors[0] === 'string') {
        throw new Error(response.errors[0]);
      }

      const token = response.values[0];
      if (typeof response.values[0] !== 'string') {
        throw new Error('Неверные почта или пароль');
      }

      runInAction(() => {
        this.loading = false;
        this.token = token;
        localStorage.setItem(StorageKeys.supportToken, token);
      });
    }
    catch (error) {
      runInAction(() => {
        this.loading = false;
        this.rootStore.errors.setError(error);
      });
    }
  }

  public async getUserData(): Promise<void> {
    try {
      runInAction(() => {
        this.data = null;
      });

      if (typeof this.token !== 'string' || this.token.length <= 0) {
        return;
      }

      const response = await this.rootStore.requests.json<IGetUserDataResponse>({
        method: 'POST',
        baseURL: this.rootStore.config.authHost,
        route: AuthBackendRoutes.getUserData,
        data: {
          token: this.token,
        },
      });

      if (typeof response.errors[0] === 'string') {
        throw new Error(response.errors[0]);
      }

      const userData = response.values[0];
      if (!userData) {
        return;
      }

      runInAction(() => {
        this.data = userData;
      });
    }
    catch (error) {
      runInAction(() => {
        this.rootStore.errors.setError(error);
      });
    }
  }


}
