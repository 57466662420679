export const HttpStatusesList = [
  200,
  201,
  204,
  400,
  401,
  403,
  404,
  500,
  502,
];
