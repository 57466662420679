import { ReactElement } from 'react';
import cn from 'classnames';
import { ITableLogsData } from '../logs-list.models';

export const StatusCell = (data: ITableLogsData): ReactElement => {
  const status = data.status > 399 ? 'error' : 'success';
  const statusClasses = {
    base: 'logs-table__status-cell__status',
    status: {
      error: 'logs-table__status-cell__status--error',
      success: 'logs-table__status-cell__status--success',
    },
  };
  const statusCN = cn(
    statusClasses.base,
    {
      [statusClasses.status[status]]: !!data.status,
    },
  );

  return (
    <div className="logs-table__status-cell">
      <div className={statusCN}>
        {data.status}
      </div>
    </div>
  );
};
