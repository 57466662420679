import { FC, useCallback, useContext } from 'react';
import { useLocation, useParams, useNavigate, generatePath } from 'react-router-dom';
import cn from 'classnames';
import { observer } from 'mobx-react';
import Tabs, { TabsProps } from 'rc-tabs';
import { ILocationState } from '../../../models/routers/location.models';
import { BrowserRoute } from '../../../routes/browser.routes';
import { appStoreContext } from '../../../stores/context.store';
import { RefreshIcon } from '../../components/icons/refresh';
import { LogsList } from '../logs-list';
import { Organizations } from '../organizations';
import { Profile } from '../profile/profile.view';
import { UserCodes } from '../user-codes';
import { Workspace } from '../workspace';
import './user-tabs.styles.scss';
import { TabNames } from './user-tabs.models';

export const UserTabs: FC<TabsProps> = observer(() => {
  const AppStore = useContext(appStoreContext);
  const { loading } = AppStore.userLogs;

  const { user_id } = useParams();

  const navigate = useNavigate();
  const location = useLocation();

  const refreshIconClasses = {
    base: 'user-tabs__tab__label-icon',
    isLoading: 'user-tabs__tab__label-icon--loading',
  };

  const refreshIconCN = cn(
    refreshIconClasses.base,
    {
      [refreshIconClasses.isLoading]: loading,
    },
  );

  const getTabName = (): string => {
    if (location.pathname.endsWith(TabNames.CODES)) {
      return TabNames.CODES;
    }
    if (location.pathname.endsWith(TabNames.ORGANIZATIONS)) {
      return TabNames.ORGANIZATIONS;
    }
    if (location.pathname.endsWith(TabNames.PROFILE)) {
      return TabNames.PROFILE;
    }
    if (location.pathname.endsWith(TabNames.WORKSPACE)) {
      return TabNames.WORKSPACE;
    }
    return TabNames.LOGS;
  };

  const tab = (location.state as ILocationState)?.tab ?? getTabName();


  const onRefreshClick = (): void => {
    if (typeof user_id === 'string' && user_id.length > 0) {
      AppStore.userLogs.getUserLogs({
        userId: user_id,
        dateStart: AppStore.userLogsFilter.startDate,
        dateEnd: AppStore.userLogsFilter.endDate,
        isSupport: AppStore.userLogsFilter.isSupport,
        service: AppStore.userLogsFilter.service,
        action: AppStore.userLogsFilter.action,
        limit: AppStore.userLogsFilter.limit,
        page: AppStore.userLogsFilter.page,
      });
    }
  };

  const logsTabLabel = (
    <div className="user-tabs__tab__label">
      Логи
      <RefreshIcon
        className={refreshIconCN}
        onClick={onRefreshClick}
      />
    </div>
  );

  const UserTabsList: TabsProps['items'] = [
    {
      key: TabNames.WORKSPACE,
      label: 'Рабочий стол',
      children: <Workspace />,
      className: 'user-tabs__tab',
    },
    {
      key: TabNames.LOGS,
      label: logsTabLabel,
      children: <LogsList />,
      className: 'user-tabs__tab',
    },
    {
      key: TabNames.ORGANIZATIONS,
      label: 'Организации',
      children: <Organizations />,
      className: 'user-tabs__tab',
    },
    {
      key: TabNames.CODES,
      label: 'Данные о письмах и СМС',
      children: <UserCodes />,
      className: 'user-tabs__tab',
    },
    {
      key: TabNames.PROFILE,
      label: 'Профиль',
      children: <Profile />,
      className: 'user-tabs__tab',
    },
  ];

  const replaceHistory = useCallback((e: string): void => {
    const tabRoutes = [BrowserRoute.codes, BrowserRoute.organizations, BrowserRoute.userLogs, BrowserRoute.profile, BrowserRoute.workspace];
    const path = tabRoutes.find((route: string) => route.endsWith(e)) || BrowserRoute.userLogs;
    navigate(generatePath(path, { user_id }), { replace: true, ...location, state: { tab: e } });
  }, [location, navigate, user_id]);

  return (
    <Tabs
      className="user-tabs"
      items={UserTabsList}
      destroyInactiveTabPane={true}
      defaultActiveKey={tab || TabNames.LOGS}
      onChange={replaceHistory}
    />
  );
});
