import { FC, useMemo } from 'react';
import cn from 'classnames';
import { ArrowLeftIcon } from '../icons/arrow-left';
import { ArrowRightIcon } from '../icons/arrow-right';
import { Select } from '../select';
import { IPaginationProps } from './pagination.models';
import './pagination.styles.scss';

export const Pagination: FC<IPaginationProps> = (props) => {
  const isFirstPage = props.page === props.minPage;
  const isLastPage = props.page === props.maxPage;

  const countText = useMemo<string>(() => {
    const firstEl = Math.max(props.page, (props.page - 1) * props.limit);
    const lastEl = Math.min(props.page * props.limit, props.total);
    return `${firstEl}-${lastEl}`;
  }, [
    props.page,
    props.limit,
    props.total,
  ]);

  const btnCN = 'pagination__button';

  const prevBtnCN = cn(
    btnCN,
    isFirstPage ? 'pagination__button_disabled' : 'pagination__button_active',
  );

  const nextBtnCN = cn(
    btnCN,
    isLastPage ? 'pagination__button_disabled' : 'pagination__button_active',
  );

  return (
    <div className="pagination__wrapper">
      <div>
        {`Всего записей: ${props.total}`}
      </div>
      <div className="pagination">
        <div className="pagination__page-limit__wrapper">
          <span>На странице по:</span>
          <Select
            className="pagination__page-limit"
            options={props.limitsOptions}
            defaultValue={props.limit}
            onChange={props.onLimitChange}
            dropTop={true}
            withShadow={false}
          />
        </div>
        <div className="pagination__page-counter">
          {`${countText} из ${props.total}`}
        </div>
        <div className="pagination__buttons">
          <button
            onClick={props.onPrevClick}
            className={prevBtnCN}
          >
            <ArrowLeftIcon />
          </button>
          <button
            onClick={props.onNextClick}
            className={nextBtnCN}
          >
            <ArrowRightIcon />
          </button>
        </div>
      </div>
    </div>
  );
};
