import { ReactElement } from 'react';
import { logActionsConstants, logActionsOneIdConstants } from '../../../../constants/log-actions.constants';
import { ITableLogsData } from '../logs-list.models';

export const ActionCell = (data: ITableLogsData): ReactElement => (
  <div className="logs-table__action-cell">
    <span className="logs-table__action__text">
      {
        { ...logActionsConstants, ...logActionsOneIdConstants }[data.action] || data.action
      }
    </span>
  </div>
);
