import { useContext } from 'react';
import { Link } from 'react-router-dom';
import { appStoreContext } from '../../../stores/context.store';
import { IIconsLink } from './users-list.models';

export const DropdownButton = ({ icon, name, className, tabs, route, id, onHandleClick }: IIconsLink): JSX.Element | null => {
  const AppStore = useContext(appStoreContext);
  const roles = AppStore.access.user?.roles || [];
  const isSubsupport = roles.filter((service) => service.role === 'subsupport').length;

  return (
    route
      ? (
        <Link
          className={className}
          to={ route }
          state={{ tab: tabs }}
        >
          <div className="container">
            <span className="icons">{icon}</span>
            <span className="icons__name">{name}</span>
          </div>
        </Link>
      ) : !isSubsupport ? (
        <div className="container" onClick={ () :void => onHandleClick?.(id)}>
          <span className="icons">{icon}</span>
          <span className="icons__name">{name}</span>
        </div>
      ) : null
  );
};
