import { FC, Fragment, useMemo } from 'react';
import {
  ITableColumn,
  Table,
} from '../../components/table';
import { OrganizationColumns } from './organizations.columns';
import { OrganizationEmpty } from './organizations.empty';
import { ITableOrganizationData, IOrganizationsTable } from './organizations.models';

export const OrganizationsTable: FC<IOrganizationsTable> = (props) => {
  const columns = useMemo<ITableColumn<ITableOrganizationData>[]>(
    () => OrganizationColumns,
    [],
  );

  const TypedTable = Table<ITableOrganizationData>;

  return (
    <Fragment>
      <div className="organizations-table__wrapper">
        <TypedTable
          columns={columns}
          data={props.organizations}
          rowRender={props.rowRender}
          className="organizations-table"
        />
      </div>
      <OrganizationEmpty
        organizations={props.organizations}
        loading={props.loading}
      />
    </Fragment>
  );
};
