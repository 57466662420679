import { FC } from 'react';
import { Link } from 'react-router-dom';
import { BrowserRoute } from '../../../routes/browser.routes';
import './not-found.styles.scss';

export const NotFound: FC = () => (
  <div className="not-found">
    <div className="not-found__content">
      <p className="not-found__title">
        Страница не найдена
      </p>
      <Link className="not-found__link" to={BrowserRoute.users} >
        Перейти на страницу поиска пользователей
      </Link>
    </div>
  </div>
);
