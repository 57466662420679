import { FC, SVGProps } from 'react';

export const RefreshIcon: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M18.3332 9.99999C18.3332 14.6 14.5998 18.3333 9.99984 18.3333C5.39984 18.3333 2.5915 13.7 2.5915 13.7M2.5915 13.7H6.35817M2.5915 13.7V17.8667M1.6665 9.99999C1.6665 5.39999 5.3665 1.66666 9.99984 1.66666C15.5582 1.66666 18.3332 6.29999 18.3332 6.29999M18.3332 6.29999V2.13332M18.3332 6.29999H14.6332"
      stroke="#0071CE"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
