import { FC, useContext } from 'react';
import { observer } from 'mobx-react';
import { appStoreContext } from '../../../stores/context.store';
import { AvatarIcon } from '../../components/icons/avatar';
import { LogoutIcon } from '../../components/icons/logout';
import './header-profile.styles.scss';

export const HeaderProfile: FC = observer(() => {
  const AppStore = useContext(appStoreContext);
  const { data } = AppStore.user;

  const logout = (): void => {
    AppStore.user.logout();
  };

  const userPassport = data?.user_info?.passport;
  const userNames = userPassport && `${userPassport.name} ${userPassport.sur_name}`.trim();

  return (
    <div className="header-profile">
      <>
        <div className="header-profile__separator" />
        {data && (
          <>
            <AvatarIcon />
            <span className="header-profile__name">
              {userNames}
            </span>
          </>
        )}
        <LogoutIcon
          className="header-profile__logout-icon"
          onClick={logout}
        />
      </>
    </div>
  );
});
