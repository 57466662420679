export enum EnvKeys {
  authHost = 'REACT_APP_ADMIN_BASE_URL',
  iframeURI = 'REACT_APP_IFRAME_URI',
  supportAPI = 'REACT_APP_SUPPORT_API',
  headerKey = 'REACT_APP_HEADER_KEY',
  localToken = 'REACT_APP_LOCAL_TOKEN',
  profileURI = 'REACT_APP_PROFILE_URI',
}

export interface IConfigStore {
  authHost: string;
  iframeURI: string;
  supportAPI: string;
  localToken: string | undefined;
  profileURI: string;
}
