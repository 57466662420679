import { FC, useContext, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { observer } from 'mobx-react';
import { appStoreContext } from '../../../stores/context.store';
import { OrganizationsTable } from './organizations.table';
import './organizations.styles.scss';

export const Organizations: FC = observer(() => {
  const AppStore = useContext(appStoreContext);
  const { data, loading } = AppStore.organizations;

  const { user_id } = useParams();

  useEffect(() => {
    if (user_id) {
      AppStore.organizations.get(user_id);
    }
  }, []);

  return (
    <div className="organizations">
      <div className="organizations__table-wrapper">
        <OrganizationsTable
          organizations={data}
          loading={loading}
        />
      </div>
    </div>
  );
});
