import { FC, PropsWithChildren } from 'react';
import { Header } from '../header';
import './layout.styles.scss';

export const Layout: FC<PropsWithChildren> = (props) => (
  <div className="layout">
    <div className="layout__header-wrapper">
      <Header />
    </div>
    {props.children}
  </div>
);
