import { FC, ReactNode, useContext, useEffect, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { observer } from 'mobx-react';
import { appStoreContext } from '../../../stores/context.store';
import { ISelectOption } from '../../components/select';
import { LogDialog } from '../log-dialog';
import { LogsListFilters } from './logs-list.filters';
import { ITableLogsData } from './logs-list.models';
import { LogsTable } from './logs-list.table';
import './logs-list.styles.scss';

export const LogsList: FC = observer(() => {
  const AppStore = useContext(appStoreContext);
  const { logs, total, loading } = AppStore.userLogs;
  const filters = AppStore.userLogsFilter;

  const [visible, setVisible] = useState(false);

  const { user_id } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const logId = searchParams.get('log_id');

  const onPrevClick = (): void => {
    onPageChange(filters.page - 1);
  };

  const onNextClick = (): void => {
    onPageChange(filters.page + 1);
  };

  const onPageChange = (page: number): void => {
    filters.setPage(page);
  };

  const onLimitChange = (option: ISelectOption): void => {
    if (typeof option === 'number') {
      filters.setLimit(option);
    }
  };

  useEffect(() => {
    filters.reset();
  }, []);

  useEffect(() => {
    if (typeof user_id === 'string' && user_id.length > 0) {
      AppStore.userLogs.getUserLogs({
        userId: user_id,
        limit: filters.limit,
        page: filters.page,
        service: filters.service,
        action: filters.action,
        dateEnd: filters.endDate,
        dateStart: filters.startDate,
        isSupport: filters.isSupport,
        status: filters.status,
      });
    }

    return () => AppStore.userLogs.reset();
  }, [
    filters.limit,
    filters.page,
    filters.service,
    filters.action,
    filters.endDate,
    filters.startDate,
    filters.isSupport,
    filters.status,
    user_id,
  ]);

  useEffect(() => {
    if (logId) {
      getLogsItem(logId);
    }
    // Видит как неиспользуемую переменную
    // eslint-disable-next-line
  }, [logId]);

  const getLogsItem = (id: string): void => {
    if (user_id) {
      AppStore.userLogsItem.getLogsItem({ userId: user_id, logId: id });
      setSearchParams(new URLSearchParams({ log_id: id }));
      setVisible(true);
    }
  };

  const closeDialog = (): void => {
    setSearchParams(new URLSearchParams());
    setVisible(false);
  };

  const rowRender = (
    row: ITableLogsData,
    rowProps: Partial<React.HTMLAttributes<HTMLDivElement>>,
    columns: ReactNode[],
  ): ReactNode => (
    <div
      onClick={(): void => getLogsItem(row.log_id)}
      key={row.log_id}
      {...rowProps}
    >
      {columns}
    </div>
  );

  return (
    <div className="logs-list">
      <LogsListFilters
        onActionChange={filters.setAction}
        onServiceChange={filters.setService}
        onEndDateChange={filters.setEndDate}
        onHttpStatusChange={filters.setStatus}
        onStartDateChange={filters.setStartDate}
        onIsSupportChange={filters.setIsSupport}
        action={filters.action}
        service={filters.service}
        isSupport={filters.isSupport}
        startDate={filters.startDate}
        endDate={filters.endDate}
        status={filters.status}
      />
      <div className="logs-list__table-wrapper">
        <LogsTable
          logs={logs}
          total={total}
          loading={loading}
          page={filters.page}
          limit={filters.limit}
          minPage={filters.minPage}
          maxPage={filters.maxPage}
          limitsOptions={filters.limitsOptions}
          rowRender={rowRender}
          onPrevClick={onPrevClick}
          onNextClick={onNextClick}
          onLimitChange={onLimitChange}
        />
      </div>
      <LogDialog
        visible={visible}
        onClose={closeDialog}
      />
    </div>
  );
});
