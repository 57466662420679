import { ITableColumn } from '../../components/table';
import {
  ActionCell,
  DurationCell,
  EventDateCell,
  IpCell,
  IsSupport,
  ServiceCell,
  StatusCell,
} from './logs-list-cells';
import { ITableLogsData } from './logs-list.models';

export const LogsListColumns: ITableColumn<ITableLogsData>[] = [
  {
    name: 'IP адрес',
    value: IpCell,
  },
  {
    name: 'Действие',
    value: ActionCell,
  },
  {
    name: 'Статус',
    value: StatusCell,
  },
  {
    name: 'Обработка запроса',
    value: DurationCell,
  },
  {
    name: 'Саппорт',
    value: IsSupport,
  },
  {
    name: 'Сервис',
    value: ServiceCell,
  },
  {
    name: 'Дата события',
    value: EventDateCell,
  },
];
