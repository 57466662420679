import { makeAutoObservable, runInAction } from 'mobx';
import { IUser } from '../models/entities/user.models';
import { IRootStore } from '../models/stores/root.models';
import { IUserDataStore } from '../models/stores/users-find.models';
import { SupportBackendRoutes } from '../routes/backend.routes';

export class UserDataStore implements IUserDataStore {

  public readonly rootStore: IRootStore;

  public data: IUser | null = null;
  public loading: boolean = false;

  constructor(rootStore: IRootStore) {
    this.rootStore = rootStore;

    makeAutoObservable(this, {
      rootStore: false,
    });
  }

  public reset(): void {
    this.data = null;
  }

  public async get(userId: string): Promise<void> {
    try {
      runInAction(() => {
        this.reset();
        this.loading = true;
      });

      const response = await this.rootStore.requests.json<IUser>({
        method: 'GET',
        baseURL: this.rootStore.config.supportAPI,
        route: SupportBackendRoutes.findUserByIds,
        token: this.rootStore.user.token,
        params: {
          userId,
        },
      });

      runInAction(() => {
        this.loading = false;
        this.data = response;
      });
    }
    catch (error) {
      runInAction(() => {
        this.reset();
        this.loading = false;
        this.rootStore.errors.setError(error);
      });
    }
  }

}
