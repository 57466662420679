import { FC, SVGProps } from 'react';

export const CloseIcon: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M23.4532 6.66663L15.9998 14.12L8.5465 6.66663L6.6665 8.54663L14.1198 16L6.6665 23.4533L8.5465 25.3333L15.9998 17.88L23.4532 25.3333L25.3332 23.4533L17.8798 16L25.3332 8.54663L23.4532 6.66663Z"
      fill="#909EBB"
    />
  </svg>
);
