import { FC, ReactNode, useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { observer } from 'mobx-react';
import { BrowserRoute } from '../../../routes/browser.routes';
import { appStoreContext } from '../../../stores/context.store';
import { ISelectOption } from '../../components/select';
import { getRouteWithParams } from '../../routes/get-route-params';
import { ISearchForm, UsersSearch } from '../users-search';
import { useUsersFilters } from './users-list.filters';
import { ITableUsersData } from './users-list.models';
import { UsersTable } from './users-list.table';
import './users-list.styles.scss';

export const UsersList: FC = observer(() => {
  const AppStore = useContext(appStoreContext);
  const { data, total, loading } = AppStore.searchUserByKey;

  const [usersSearchParams, setUsersSearchParams] = useState<ISearchForm>({});

  const filters = useUsersFilters({
    limitsOptions: [10, 15, 20],
    total,
  });

  const onPrevClick = (): void => {
    onPageChange(filters.page - 1);
  };

  const onNextClick = (): void => {
    onPageChange(filters.page + 1);
  };

  const onPageChange = (newPage: number): void => {
    filters.setPage(newPage);
  };

  const onLimitChange = (option: ISelectOption): void => {
    if (typeof option === 'number') {
      filters.setLimit(option);
    }
  };

  useEffect(() => {
    if (Object.keys(usersSearchParams).length !== 0) {
      AppStore.searchUserByKey.get({
        ...usersSearchParams,
        limit: filters.limit,
        page: filters.page,
      });
    }
  }, [
    usersSearchParams.id,
    usersSearchParams.phone,
    usersSearchParams.email,
    usersSearchParams.docId,
    usersSearchParams.snils,
    usersSearchParams.name,
    usersSearchParams.surName,
    usersSearchParams.patronymic,
    filters.limit,
    filters.page,
  ]);

  const rowRender = (
    row: ITableUsersData,
    rowProps: Partial<React.HTMLAttributes<HTMLDivElement>>,
    columns: ReactNode[],
  ): ReactNode => {
    const route = getRouteWithParams(BrowserRoute.user, {
      user_id: row._id,
    });

    return (
      <Link
        to={route}
        className={rowProps.className}
        key={row._id}
      >
        {columns}
      </Link>
    );
  };

  const onSubmitHandler = (searchFormValues: ISearchForm): void => {
    setUsersSearchParams(searchFormValues);
  };

  return (
    <div className="users-list">
      <UsersSearch
        onSubmitHandler={onSubmitHandler}
      />
      <div className="users-list__table-wrapper">
        <UsersTable
          total={total}
          users={data}
          rowRender={rowRender}
          loading={loading}
          page={filters.page}
          limit={filters.limit}
          minPage={filters.minPage}
          maxPage={filters.maxPage}
          limitsOptions={filters.limitsOptions}
          onPrevClick={onPrevClick}
          onNextClick={onNextClick}
          onLimitChange={onLimitChange}
        />
      </div>
    </div>
  );
});
