import { FC } from 'react';
import cn from 'classnames';
import { IButtonProps } from './button.models';
import './button.styles.scss';

export const Button: FC<IButtonProps> = ({
  variant = 'primary',
  variantStyle,
  size = 'md',
  children,
  ...props
}) => {
  const btnClasses = {
    base: 'button',
    disabled: 'button_disabled',
    size: {
      none: 'button_none',
      sm: 'button_sm',
      md: 'button_md',
    },
    variant: {
      outline: 'button_outline',
      gray: 'button_gray',
      primary: 'button_primary',
      success: 'button_success',
      warning: 'button_warning',
      danger: 'button_danger',
      gradient: 'button_gradient',
      white: 'button_white',
    },
    variantStyle: {
      standard: 'button_standard',
    },
  };

  const buttonCN = cn(
    props.className,
    btnClasses.base,
    btnClasses.size[size],
    {
      [btnClasses.variant[variant]]: !props.disabled,
      [btnClasses.disabled]: props.disabled,
    },
    variantStyle && btnClasses.variantStyle[variantStyle],
  );

  return (
    <button
      {...props}
      className={buttonCN}
    >
      {children}
    </button>
  );
};
