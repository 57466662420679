import { FC, Fragment, HTMLAttributes, ReactNode, useMemo } from 'react';
import cn from 'classnames';
import { IUser } from '../../../models/entities/user.models';
import { NotFoundFilesIcon } from '../../components/icons/not-found-files';
import './users-list.styles.scss';
import { Loader } from '../../components/loader';

export interface IUsersListEmptyProps extends HTMLAttributes<HTMLDivElement> {
  users: IUser[] | null;
  loading?: boolean;
  children?: ReactNode | undefined;
}

export const UsersListEmpty: FC<IUsersListEmptyProps> = ({
  users,
  loading,
  ...props
}) => {
  const usersIsEmpty = useMemo(() => (users === null), [users]);
  const usersNotFound = useMemo(() => (Array.isArray(users) && users.length <= 0), [users]);

  const rootCN = cn('users-list-empty', props.className);

  if (loading) {
    return (
      <div className={rootCN} {...props}>
        <div className="users-list-empty__content">
          <Loader />
        </div>
      </div>
    );
  }

  if (!usersNotFound && !usersIsEmpty) {
    return (
      <Fragment>
        {props.children}
      </Fragment>
    );
  }

  if (usersIsEmpty) {
    return (
      <div className={rootCN} {...props}>
        <div className="users-list-empty__content">
          <NotFoundFilesIcon className="users-list-empty__icon" />
          <h3 className="users-list-empty__content__title">
            Таблица пустая
          </h3>
        </div>
      </div>
    );
  }

  return (
    <div className={rootCN} {...props}>
      <div className="users-list-empty__content">
        <NotFoundFilesIcon className="users-list-empty__icon" />
        <h3 className="users-list-empty__content__title">
          Пользователи не найдены
        </h3>
        <p className="users-list-empty__content__subtitle">
          Проверьте правильность набора или используйте фильтры
        </p>
      </div>
    </div>
  );
};
