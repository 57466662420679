import { FC, SVGProps } from 'react';

export const OrganizationIcon: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_518_5682)">
      <path
        d="M10.0003 15.3333H8.66699V14H10.0003V15.3333ZM12.667 14H11.3337V15.3333H12.667V14ZM10.0003 11.3333H8.66699V12.6666H10.0003V11.3333ZM4.66699 14H3.33366V15.3333H4.66699V14ZM4.66699 11.3333H3.33366V12.6666H4.66699V11.3333ZM12.667 11.3333H11.3337V12.6666H12.667V11.3333ZM10.0003 8.66663H8.66699V9.99996H10.0003V8.66663ZM12.667 8.66663H11.3337V9.99996H12.667V8.66663ZM14.0003 5.99996C14.3539 5.99996 14.6931 6.14044 14.9431 6.39048C15.1932 6.64053 15.3337 6.97967 15.3337 7.33329V15.3333H14.0003V7.33329H7.33366V15.3333H6.00033V9.99996H2.00033V15.3333H0.666992V9.99996C0.666992 9.64634 0.807468 9.3072 1.05752 9.05715C1.30756 8.8071 1.6467 8.66663 2.00033 8.66663H6.00033V7.33329C6.00033 6.97967 6.1408 6.64053 6.39085 6.39048C6.6409 6.14044 6.98004 5.99996 7.33366 5.99996V4.66663C7.33366 4.313 7.47413 3.97387 7.72418 3.72382C7.97423 3.47377 8.31337 3.33329 8.66699 3.33329H10.0003V0.666626H11.3337V3.33329H12.667C13.0206 3.33329 13.3598 3.47377 13.6098 3.72382C13.8598 3.97387 14.0003 4.313 14.0003 4.66663V5.99996ZM12.667 5.99996V4.66663H8.66699V5.99996H12.667Z"
        fill="#0071CE"
      />
    </g>
    <defs>
      <clipPath id="clip0_518_5682">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
