import { FC, SVGProps } from 'react';

export const CopyIcon: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="12"
    height="14"
    viewBox="0 0 12 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M2.66667 2.99992V0.999919C2.66667 0.823108 2.7369 0.653538 2.86193 0.528514C2.98695 0.40349 3.15652 0.333252 3.33333 0.333252H11.3333C11.5101 0.333252 11.6797 0.40349 11.8047 0.528514C11.9298 0.653538 12 0.823108 12 0.999919V10.3333C12 10.5101 11.9298 10.6796 11.8047 10.8047C11.6797 10.9297 11.5101 10.9999 11.3333 10.9999H9.33333V12.9999C9.33333 13.3679 9.03333 13.6666 8.662 13.6666H0.671333C0.583418 13.6671 0.496265 13.6503 0.414882 13.617C0.333498 13.5837 0.259489 13.5347 0.197106 13.4728C0.134723 13.4108 0.0851953 13.3372 0.0513695 13.256C0.0175437 13.1749 8.62495e-05 13.0878 0 12.9999L0.00200001 3.66659C0.00200001 3.29859 0.302 2.99992 0.673334 2.99992H2.66667ZM1.33533 4.33325L1.33333 12.3333H8V4.33325H1.33533ZM4 2.99992H9.33333V9.66659H10.6667V1.66659H4V2.99992Z" fill="#0071CE" />
  </svg>

);
